import { useEffect, useRef, useState } from "react"
import constant from "../Services/constant"
import { ApiService } from "../Services/apiservices"
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from "react-router-dom"
const TrendingPlayer = () => {
    const didMountRef = useRef(true)
    const [playersList, setPlayersList] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            trendingPlayers()
        }
        didMountRef.current = false
    }, [])

    const trendingPlayers = () => {
        ApiService.fetchData("trendingPlayers").then((res) => {
            if (res.status === "success") {
                setPlayersList(res.playerData)
            }
            setLoading(false)
        })
    }

    const capitalize = (str) => {
        return str ? str.toUpperCase() : '';
    };
    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };
    const handleLink = (playerId) => {
        window.location.href=`/player-detail/${playerId}`;
    };
    return (
        <>
            {loading ? (
                <>
                    <div className="section-title">
                        <h6 className="mb-0"><Skeleton width={140} height={20} /></h6>
                        <a href="javascript:void(0)" className="viewallbutton"><Skeleton height={20} width={80} /></a>
                    </div>
                    <div className="cardBox mb-30">
                        {[...Array(4)].map((_, index) => (
                            <div className="playerBox" key={index}>
                                <div className="playerBoxMedia"><Skeleton height={50} width={50} /></div>
                                <div className="playerBoxContent">
                                    <h2 className="playerName"><Skeleton height={20} width={150} /></h2>
                                    <div className="playerStats">
                                        <div className="playerStats-inner">
                                            <span className="playerteamname"><Skeleton height={15} width={20} /></span>
                                            <span><Skeleton height={15} width={20} /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                playersList && playersList.length > 0 && (
                    <>
                        <div className="section-title">
                            <h6 className="mb-0">Trending Player</h6>
                            <a href="/players" className="viewallbutton">View All</a>
                        </div>
                        <div className="cardBox mb-30">
                            {playersList.map((players, index) => (
                                <div className="playerBox" key={index}>
                                    <div className="playerBoxMedia" onClick={(e) => handleLink(players.player_player_api_id)}>
                                        <img
                                            src={players.player_logo_url ? players.player_logo_url : constant.DEFAULT_IMAGE}
                                            alt={players.player_title}
                                            onError={handleImageError}
                                        />
                                    </div>
                                    <div className="playerBoxContent" onClick={(e) => handleLink(players.player_player_api_id)}>
                                        <h2 className="playerName">{players.player_title}</h2>
                                        <div className="playerStats">
                                            <div className="playerStats-inner">
                                            {players.player_team_name_short && (<span className="playerteamname">{capitalize(players.player_team_name_short)}</span>)}
                                            {players.player_play_role && (<span>{players.player_play_role}</span>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )
            )}

        </>
    )
}

export default TrendingPlayer
const MatchGraph = () => {
    return (
        <>
            <div className="mb-3">
                <div className="section-title">
                    <h5 className="mb-0">Graph</h5>
                </div>
            </div>
        </>
    )
}

export default MatchGraph
import { useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import DataContext from '../Elements/event_context';
const SearchModal = () => {
    const contextValues = useContext(DataContext);
    const toggleSearchModal = () => {
        contextValues.setToggleSearchModal(!contextValues.toggleSearchModal)
    }
    return (
    <>
        <Modal show={contextValues.toggleSearchModal} onHide={toggleSearchModal}>
            <Modal.Header closeButton>
                <Modal.Title>Search</Modal.Title>
            </Modal.Header>  
        </Modal>
    </>)
}

export default SearchModal
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ApiService } from "../../../Component/Services/apiservices"
import Skeleton from 'react-loading-skeleton'
import { formatPublishDateWithDay } from "../../../Component/Services/moment"
import MatchCard from "../../../Component/Elements/match_card"
const SeriesMatches = ({ seriesDetail }) => {
    const didMountRef = useRef(true)
    const [matchesList, setMatchesList] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (seriesDetail.series_cid) {
            getSeriesData()
        }
    }, [seriesDetail.series_cid])

    const getSeriesData = () => {
        const dataString = {
            series_cid: seriesDetail.series_cid
        }
        ApiService.postData("series/seriesMatches", dataString).then((res) => {
            if (res.status === "success") {
                setMatchesList(Object.entries(res.resData).map(([date, data]) => ({
                    date,
                    data,
                })))
            }
            setLoading(false)
        })
    }

    const handleLink = (matchId) => {
        navigate(`/match-detail/${matchId}`);
    };
    return (
        <>
            {loading ?
                <div>
                    <div className="section-title">
                        <h5 className="mb-0"><Skeleton width={150} height={25} /></h5>
                    </div>
                    <div className="row g-3">
                        {Array.from({ length: 5 }).map((_, index) => (
                            <div className="col-lg-6" key={index}>
                                <div className="matchCard mt-0">
                                    <div className="matchCardHeader">
                                        <div className="left">
                                            <span className="seriesname"><Skeleton width={150} height={15} /></span>
                                        </div>
                                        <div className="right justify-content-end">
                                            <span className="finished"><Skeleton width={50} height={15} /></span>
                                        </div>
                                    </div>
                                    <div className="matchCardMiddle">
                                        <div className="matchCardMiddleLeft">
                                            <div className="matchTeam">
                                                <figure className="matchTeamMedia">
                                                    <Skeleton width={20} height={20} circle />
                                                </figure>
                                                <Skeleton width={20} height={20} className="bimg" circle />
                                                <h6 className="mb-0 matchTeamname"><Skeleton width={50} height={15} /></h6>
                                            </div>
                                            <div className="matchTeam">
                                                <figure className="matchTeamMedia">
                                                    <Skeleton width={20} height={20} circle />
                                                </figure>
                                                <Skeleton width={20} height={20} className="bimg" circle />
                                                <h6 className="mb-0 matchTeamname"><Skeleton width={50} height={15} /></h6>
                                            </div>
                                        </div>
                                        <div className="matchCardMiddleRight">
                                            <div className="matchCardScore">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="matchCardFooter">
                                        <div className="left">
                                            <ul>
                                                <li><Skeleton width={50} height={10} /></li>
                                                <li><Skeleton width={50} height={10} /></li>
                                            </ul>
                                        </div>
                                        <div className="right">
                                            <span className="name"><Skeleton width={20} height={15} /></span>
                                            <span className="back"><Skeleton width={20} height={15} /></span>
                                            <span className="lay"><Skeleton width={20} height={15} /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                :
                <>
                    {matchesList && matchesList.length > 0 ?
                        <>
                            {matchesList.map((date, index) => (
                                <div className="mb-3" key={index}>
                                    <div className="section-title">
                                        <h5 className="mb-0">{formatPublishDateWithDay(date.date)}</h5>
                                    </div>
                                    <div className="row g-3">
                                        {date.data.map((matchlist, index) => (
                                            <div className="col-lg-6" key={index}>
                                                <MatchCard matchlist={matchlist} className={'mt-0'}/> 
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </>
                        : null}

                </>
            }
        </>

    )
}

export default SeriesMatches
import { useEffect, useRef, useState } from "react"
import CurrentSeries from "../../Component/Elements/current_series"
import LatestNews from "../../Component/Elements/latest_news"
import TrendingPlayer from "../../Component/Elements/trending_player"
import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { Breadcrumb } from "react-bootstrap"
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import { ApiService } from "../../Component/Services/apiservices"
import MatchCard from "../../Component/Elements/match_card"
const Fixtures = () => {
    const didMountRef = useRef(true)
    const today = moment().startOf('day'); // Get the current date

    const [activeDate, setActiveDate] = useState(today);
    const [matchesDate, setMatchesDate] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (didMountRef.current) {
            getFixturesData(activeDate)
        }
        didMountRef.current = false
    }, [])

    const generateDates = () => {
        const dates = [];
        for (let i = 3; i > 0; i--) {
            let pastDate = moment().subtract(i, 'days');
            dates.push(pastDate);
        }
        dates.push(moment());
        for (let i = 1; i <= 10; i++) {
            let futureDate = moment().add(i, 'days');
            dates.push(futureDate);
        }
        return dates;
    };

    const dates = generateDates();

    const handleDateClick = (date) => {
        setActiveDate(date);
        getFixturesData(date)
    };

    const getFixturesData = (activeNewDate) => {
        const dataString = {
            date: activeNewDate.toString()
        }
        setLoading(true)
        ApiService.postData("fixturesData", dataString).then((res) => {
            if (res.status === "success") {
                setMatchesDate(res.data)
                setLoading(false)
            } else {
                setLoading(false)
            }

        })
    }

    return (<>
        <Header></Header>
        <div className="pageSubheader">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <Breadcrumb>
                            {loading ?
                                <>
                                    <Breadcrumb.Item href="/"><Skeleton width={40} height={15} /></Breadcrumb.Item>
                                    <Breadcrumb.Item href="/"><Skeleton width={100} height={15} /></Breadcrumb.Item>
                                </>
                                :
                                <>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="/fixtures">Fixtures</Breadcrumb.Item>
                                </>
                            }
                        </Breadcrumb>
                    </div>
                </div>
            </div>
        </div>
        <section className="section-gap-small pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        <div className="fixture-calendar mb-30">
                            <div className="fixture-slider">
                                <Swiper spaceBetween={15}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 4,
                                        },
                                        992: {
                                            slidesPerView: 8,
                                        },
                                    }}
                                >
                                    {dates.map((date, index) => {
                                        const isActive = activeDate.isSame(date, 'day');
                                        return (
                                            <SwiperSlide key={index}>
                                                <p
                                                    className={`text-center mt-3 ${isActive ? 'active' : ''}`}
                                                    onClick={(e) => handleDateClick(date)}
                                                    style={{ cursor: 'pointer' }}                                                 >
                                                    {date.format('ddd')} <br />{date.format('DD MMM')}
                                                </p>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            <div className="fixture-button-group">
                                <button type="button" className="calendar-button">Full Calendar <i className="ri-calendar-2-line ri-xl"></i></button>
                            </div>
                        </div> 
                        <>
                            {loading ?
                                <div>
                                    <div className="section-title">
                                        <h5 className="mb-0"><Skeleton width={150} height={25} /></h5>
                                    </div>
                                    <div className="row g-3">
                                        {Array.from({ length: 5 }).map((_, index) => (
                                            <div className="col-lg-6" key={index}>
                                                <div className="matchCard mt-0">
                                                    <div className="matchCardHeader">
                                                        <div className="left">
                                                            <span className="seriesname"><Skeleton width={150} height={15} /></span>
                                                        </div>
                                                        <div className="right justify-content-end">
                                                            <span className="finished"><Skeleton width={50} height={15} /></span>
                                                        </div>
                                                    </div>
                                                    <div className="matchCardMiddle">
                                                        <div className="matchCardMiddleLeft">
                                                            <div className="matchTeam">
                                                                <figure className="matchTeamMedia">
                                                                    <Skeleton width={20} height={20} circle />
                                                                </figure>
                                                                <Skeleton width={20} height={20} className="bimg" circle />
                                                                <h6 className="mb-0 matchTeamname"><Skeleton width={50} height={15} /></h6>
                                                            </div>
                                                            <div className="matchTeam">
                                                                <figure className="matchTeamMedia">
                                                                    <Skeleton width={20} height={20} circle />
                                                                </figure>
                                                                <Skeleton width={20} height={20} className="bimg" circle />
                                                                <h6 className="mb-0 matchTeamname"><Skeleton width={50} height={15} /></h6>
                                                            </div>
                                                        </div>
                                                        <div className="matchCardMiddleRight">
                                                            <div className="matchCardScore">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="matchCardFooter">
                                                        <div className="left">
                                                            <ul>
                                                                <li><Skeleton width={50} height={10} /></li>
                                                                <li><Skeleton width={50} height={10} /></li>
                                                            </ul>
                                                        </div>
                                                        <div className="right">
                                                            <span className="name"><Skeleton width={20} height={15} /></span>
                                                            <span className="back"><Skeleton width={20} height={15} /></span>
                                                            <span className="lay"><Skeleton width={20} height={15} /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                :
                                <>
                                    {matchesDate && matchesDate.length > 0 ?
                                        <>
                                            {matchesDate.map((value, index) => (
                                                value.has_many_upcoming_matches.length > 0 && (
                                                    <div className="mb-3" key={index}>
                                                        <div className="section-title mb-20">
                                                            <h5 className="mb-0">{value.series_title}</h5>
                                                        </div>
                                                        <div className="row g-3">
                                                            {value.has_many_upcoming_matches.map((matchlist, matchIndex) => (
                                                                <div className="col-lg-6" key={matchIndex}>
                                                                    <MatchCard matchlist={matchlist} className={'mt-0'} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )
                                            ))}
                                        </>
                                        : null}
                                </>
                            }
                        </>
                    </div>
                    <div className="col-lg-3">
                        <div className="seriesSidebar">
                            <TrendingPlayer />
                            <CurrentSeries />
                            <LatestNews />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </>)
}

export default Fixtures
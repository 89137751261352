import Footer from "../Footer"
import Header from "../Header"

const VenueDetail = () => {
    return (
        <>
            <Header></Header>
            <div>
                <div className="section-title">
                    <h5 className="mb-0">Venue Detail</h5>
                </div>
            </div>
            <Footer></Footer>
        </>

    )
}

export default VenueDetail
import constant from "../../../Component/Services/constant";
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../Component/Services/apiservices";
import { useParams } from "react-router-dom"
import Skeleton from 'react-loading-skeleton'

const MatchInfo = ({ matchDetail }) => {
    console.log(matchDetail);
    const [activeTab, setActiveTab] = useState('ALL');
    const [MatchActiveTab, setMatchActiveTab] = useState('ALL');
    const { match_id } = useParams()
    const [headToHeadTossCompareInfo, setHeadToHeadTossCompareInfo] = useState({});
    const [headToHeadMatchCompareInfo, setHeadToHeadMatchCompareInfo] = useState({});

    const [loading, setLoading] = useState(true);
    const [HeadMatchLoading, setHeadMatchLoading] = useState(true);

    const didMountRef = useRef(true);

    useEffect(() => {
        if (didMountRef.current) {
            headToHeadTossCompare(activeTab);
            headToHeadMatchCompare(MatchActiveTab);
            didMountRef.current = false;
        }
    }, []);

    const headToHeadTossCompare = (activeTab) => {
        setLoading(true);
        ApiService.fetchData("headToHeadTossCompare/"+match_id+'/'+activeTab)
            .then((res) => {                
                if (res.status == 'success') {
                    setHeadToHeadTossCompareInfo(res.tossCompareResults);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };  

    const headToHeadMatchCompare = (MatchActiveTab) => {
        setHeadMatchLoading(true);
        ApiService.fetchData("headToHeadMatchCompare/"+match_id+'/'+MatchActiveTab)
            .then((res) => {                
                if (res.status == 'success') {
                    setHeadToHeadMatchCompareInfo(res.matchCompareResults);
                }
                setHeadMatchLoading(false);
            })
            .catch(() => {
                setHeadMatchLoading(false);
            });
    }; 

    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        headToHeadTossCompare(tab);
    };

    const handleMatchTabClick = (tab) => {
        setMatchActiveTab(tab);
        headToHeadMatchCompare(tab);
    };

    return (
        <>
            <div className="mb-20">
                <div className="section-title">
                    <h5 className="mb-0">Info</h5>
                </div>
                <div className="cardBox p-15">
                    <div className="row">
                        <div className="col-lg-6 border-right">
                            <div className="list-box">
                                <ul>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/01.png"></img>
                                            <span className="tx-gray-500">Match</span>
                                        </div>
                                        <span>{matchDetail.match_title}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/00.png"></img>
                                            <span className="tx-gray-500">Series</span>
                                        </div>
                                        <span>{matchDetail.match_series}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/02.png"></img>
                                            <span className="tx-gray-500">Date</span>
                                        </div>
                                        <span>{matchDetail.match_date_wise}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/03.png"></img>
                                            <span className="tx-gray-500">Time</span>
                                        </div>
                                        <span>{matchDetail.match_match_time}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/04.png"></img>
                                            <span className="tx-gray-500">Toss</span>
                                        </div>
                                        <span>{matchDetail.match_toss ? matchDetail.match_toss : '-----'}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/05.png"></img>
                                            <span className="tx-gray-500">Venue</span>
                                        </div>
                                        <span>{matchDetail.match_venue_name}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="list-box pl-15">
                                <ul>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/06.png"></img>
                                            <span className="tx-gray-500">Umpire</span>
                                        </div>
                                        <span>{matchDetail.match_umpire}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/06.png"></img>
                                            <span className="tx-gray-500">Third Umpire</span>
                                        </div>
                                        <span>{matchDetail.match_third_umpire}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/07.png"></img>
                                            <span className="tx-gray-500">Referee</span>
                                        </div>
                                        <span>{matchDetail.match_referee}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/08.png"></img>
                                            <span className="tx-gray-500">Player O.T.M</span>
                                        </div>
                                        <span>{matchDetail.match_player_otm ? matchDetail.match_player_otm : '-----'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-20">
                <div className="section-title">
                    <h5 className="mb-0">Venue</h5>
                </div>
                <div className="cardBox p-15">
                    <div className="row">
                        <div className="col-lg-6 border-right">
                            <div className="list-box">
                                <ul>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/09.png"></img>
                                            <span className="tx-gray-500">Stadium</span>
                                        </div>
                                        <span>{matchDetail.match_stadium ? matchDetail.match_stadium : '-----'}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/10.png"></img>
                                            <span className="tx-gray-500">City</span>
                                        </div>
                                        <span>{matchDetail.match_venue_location ? matchDetail.match_venue_location : '-----'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="list-box pl-15">
                                <ul>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/11.png"></img>
                                            <span className="tx-gray-500">Capacity</span>
                                        </div>
                                        <span>{matchDetail.match_capacity ? matchDetail.match_capacity : '-----'}</span>
                                    </li>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/12.png"></img>
                                            <span className="tx-gray-500">Ends</span>
                                        </div>
                                        <span>{matchDetail.match_ends ? matchDetail.match_ends : '-----'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-20">
                <div className="section-title">
                    <h5 className="mb-0">TV Guide In</h5>
                </div>
                <div className="cardBox p-15">
                    <div className="row">
                        <div className="col-lg-6 border-right">
                            <div className="list-box">
                                <ul>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/13.png"></img>
                                            <span className="tx-gray-500">Streaming</span>
                                        </div>
                                        <span>{matchDetail.match_streaming ? matchDetail.match_streaming : '-----'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="list-box pl-15">
                                <ul>
                                    <li>
                                        <div className="lbic">
                                            <img src="/img/14.png"></img>
                                            <span className="tx-gray-500">TV</span>
                                        </div>
                                        <span>{matchDetail.match_tv ? matchDetail.match_tv : '-----'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-20">
                <div className="section-title">
                    <h5 className="mb-0">Head to Head Toss Compare</h5>
                </div>
                <div className="cardBox p-15">
                    <div className="ctabs mb-15">
                        <ul>
                            {['ALL', 'T20', 'ODI', 'Test'].map((tab) => (
                                <li
                                    key={tab}
                                    className={activeTab === tab ? 'active' : ''}
                                    onClick={() => handleTabClick(tab)}
                                >
                                    {tab === 'ALL' ? 'All' : tab === 'Test' ? 'Test' : tab + "'s"}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* Team A Toss Compare */}
                <div className="htosbox mt-20">
                    <div className="htosbox-team">
                        <img
                            src={matchDetail.match_teama_image}
                            alt={matchDetail.match_teama_name}
                            onError={handleImageError}
                        />
                        <h6 className="mb-0 tx-14">{matchDetail.match_teama_name}</h6>
                    </div>
                    {loading ? (
                       
                        <div className="htosbox-result">
                                <ul>
                        {[...Array(5)].map((_, index) => (
                        <li className="star">
                            <Skeleton circle={true} width={30} height={30} />
                            </li>   
                        ))}   
                        </ul>
                        </div>
                        
                        ) : (
                        <>
                            <div className="htosbox-result">
                                <ul>
                                    <li className="star">
                                        <i className="ri-star-fill"></i>
                                    </li>
                                    {headToHeadTossCompareInfo && headToHeadTossCompareInfo.teamA && headToHeadTossCompareInfo.teamA.length>0 && headToHeadTossCompareInfo.teamA.map((result, index) => (
                                        <li key={index} className={result}>
                                            {result.charAt(0).toUpperCase()}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            </>
                            )}
                </div>
           {/* Team B Toss Compare */}
           <div className="htosbox mt-20">
                    <div className="htosbox-team">
                        <img
                            src={matchDetail.match_teamb_image}
                            alt={matchDetail.match_teamb_name}
                            onError={handleImageError}
                        />
                        <h6 className="mb-0 tx-14">{matchDetail.match_teamb_name}</h6>
                    </div>
                    {loading ? (
                       
                       <div className="htosbox-result">
                               <ul>
                       {[...Array(5)].map((_, index) => (
                       <li className="star">
                           <Skeleton circle={true} width={30} height={30} />
                           </li>   
                       ))}   
                       </ul>
                       </div>
                       
                       ) : (
                       <>
                    <div className="htosbox-result">
                        <ul>
                            <li className="star">
                                <i className="ri-star-fill"></i>
                            </li>
                            {headToHeadTossCompareInfo && headToHeadTossCompareInfo.teamB && headToHeadTossCompareInfo.teamB.length>0 && headToHeadTossCompareInfo.teamB.map((result, index) => (
                                <li key={index} className={result}>
                                    {result.charAt(0).toUpperCase()}
                                </li>
                            ))}
                        </ul>
                    </div>
                    </>
                            )}
                </div>
                </div>
            </div>
            <div className="mb-20">
                <div className="section-title">
                    <h5 className="mb-0">Team Toss Compare</h5>
                </div>
                <div className="cardBox p-15">
                    <div className="ctabs mb-15">
                        <ul>
                            <li className="active">All</li>
                            <li>T20's</li>
                            <li>ODI's</li>
                            <li>Test</li>
                        </ul>
                    </div>
                    <div className="htosbox mt-20">
                        <div className="htosbox-team">
                            <img src={matchDetail.match_teama_image} alt={matchDetail.match_teama_name} onError={handleImageError}></img>
                            <h6 className="mb-0 tx-14">{matchDetail.match_teama_name}</h6>
                        </div>
                        <div className="htosbox-result">
                            <ul>
                                <li className="star"><i className="ri-star-fill"></i></li>
                                <li className="loose">L</li>
                                <li className="loose">L</li>
                                <li className="win">W</li>
                            </ul>
                        </div>
                    </div>
                    <div className="htosbox mt-20">
                        <div className="htosbox-team">
                            <img src={matchDetail.match_teamb_image} alt={matchDetail.match_teamb_name} onError={handleImageError}></img>
                            <h6 className="mb-0 tx-14">{matchDetail.match_teamb_name}</h6>
                        </div>
                        <div className="htosbox-result">
                            <ul>
                                <li className="star"><i className="ri-star-fill"></i></li>
                                <li className="loose">L</li>
                                <li className="loose">L</li>
                                <li className="win">W</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-20">
                <div className="section-title">
                    <h5 className="mb-0">Head to Head Match Compare</h5>
                </div>
                <div className="cardBox p-15">
                    <div className="ctabs mb-15">
                    <ul>
                            {['ALL', 'T20', 'ODI', 'Test'].map((tab) => (
                                <li
                                    key={tab}
                                    className={MatchActiveTab === tab ? 'active' : ''}
                                    onClick={() => handleMatchTabClick(tab)}
                                >
                                    {tab === 'ALL' ? 'All' : tab === 'Test' ? 'Test' : tab + "'s"}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="htosbox mt-20">
                        <div className="htosbox-team">
                            <img src={matchDetail.match_teama_image} alt={matchDetail.match_teama_name} onError={handleImageError}></img>
                            <h6 className="mb-0 tx-14">{matchDetail.match_teama_name}</h6>
                        </div>
                        {HeadMatchLoading ? (
                       
                       <div className="htosbox-result">
                               <ul>
                       {[...Array(5)].map((_, index) => (
                       <li className="star">
                           <Skeleton circle={true} width={30} height={30} />
                           </li>   
                       ))}   
                       </ul>
                       </div>
                       
                       ) : (
                       <>
                           <div className="htosbox-result">
                               <ul>
                                   <li className="star">
                                       <i className="ri-star-fill"></i>
                                   </li>
                                   {headToHeadMatchCompareInfo && headToHeadMatchCompareInfo.teamA && headToHeadMatchCompareInfo.teamA.length>0 && headToHeadMatchCompareInfo.teamA.map((result, index) => (
                                       <li key={index} className={result}>
                                           {result.charAt(0).toUpperCase()}
                                       </li>
                                   ))}
                               </ul>
                           </div>
                           </>
                           )}
                    </div>
                    <div className="htosbox mt-20">
                        <div className="htosbox-team">
                            <img src={matchDetail.match_teamb_image} alt={matchDetail.match_teamb_name} onError={handleImageError}></img>
                            <h6 className="mb-0 tx-14">{matchDetail.match_teamb_name}</h6>
                        </div>
                        {HeadMatchLoading ? (
                       
                       <div className="htosbox-result">
                               <ul>
                       {[...Array(5)].map((_, index) => (
                       <li className="star">
                           <Skeleton circle={true} width={30} height={30} />
                           </li>   
                       ))}   
                       </ul>
                       </div>
                       
                       ) : (
                       <>
                    <div className="htosbox-result">
                        <ul>
                            <li className="star">
                                <i className="ri-star-fill"></i>
                            </li>
                            {headToHeadMatchCompareInfo && headToHeadMatchCompareInfo.teamB && headToHeadMatchCompareInfo.teamB.length>0 && headToHeadMatchCompareInfo.teamB.map((result, index) => (
                                <li key={index} className={result}>
                                    {result.charAt(0).toUpperCase()}
                                </li>
                            ))}
                        </ul>
                    </div>
                    </>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MatchInfo
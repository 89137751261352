import Footer from "../../Component/Footer"
import Header from "../../Component/Header"

const News=()=>{
    return(<>
    <Header></Header>

    <Footer></Footer>
    </>)
}

export default News
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../Component/Services/apiservices";
import Skeleton from 'react-loading-skeleton';
import moment from "moment";
const SeriesStats = ({ seriesDetail }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [subtype, setSubtype] = useState(1); // Default: Most Runs for Batting
    const [statsData, setStatsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [formattedDate, setFormattedDate] = useState('');

    const didMountRef = useRef(true);

    useEffect(() => {
        if (didMountRef.current) {
            fetchStatsData(activeTab, subtype); // Fetch initial stats
            const date = moment().format('D MMMM YYYY, HH:mm [IST]');
            setFormattedDate(date);
            didMountRef.current = false;
        }
    });

    const fetchStatsData = (activeTab, subtype) => {
        setLoading(true);

        // Create a new FormData object and append the necessary data
        const formData = new FormData();
        formData.append('series_id', seriesDetail.series_cid);
        formData.append('type', activeTab); // 1 for Batting, 2 for Bowling
        formData.append('sub_type', subtype);

        ApiService.postCricketData("seriesStatsBySeriesId/", formData).then((res) => {
            if (res.status) {
                updateTableDataAndHeaders(res.data, activeTab, subtype);
            }
            setTimeout(() => {
                setLoading(false);
            }, 500);

        }).catch(() => {
            setLoading(false);
        });
    };

    const updateTableDataAndHeaders = (data, activeTab, subtype) => {
        let headers = [];
        let dataKey = "";

        if (activeTab === 1) {
            switch (subtype) {
                case '1': // Most Runs
                    dataKey = 'most_runs';
                    headers = ['No.', 'Players', 'Matches', 'Innings', 'Runs', 'Strike Rate', 'Avg', 'Fours', 'Sixes'];
                    break;
                case '2': // Highest Score
                    dataKey = 'highest_scores';
                    headers = ['No.', 'Players', 'Runs', 'Balls', 'Strike Rate', 'Fours', 'Sixes'];
                    break;
                case '3': // Best Batting Avg
                    dataKey = 'batting_avg';
                    headers = ['No.', 'Players', 'Matches', 'Innings', 'Runs', 'Avg'];
                    break;
                case '4': // Best Batting Avg
                    dataKey = 'batting_sr';
                    headers = ['No.', 'Players', 'Matches', 'Innings', 'Runs', 'Strike Rate', 'Avg'];
                    break;
                case '5': // Best Batting Avg
                    dataKey = 'most_hundreds';
                    headers = ['No.', 'Players', 'Matches', 'Innings', 'Runs', 'High Score', "Hundreds"];
                    break;
                case '6': // Best Batting Avg
                    dataKey = 'most_fifty';
                    headers = ['No.', 'Players', 'Matches', 'Innings', 'Runs', 'High Score', "Fifties"];
                    break;
                case '7': // Best Batting Avg
                    dataKey = 'most_fours';
                    headers = ['No.', 'Players', 'Matches', 'Innings', 'Runs', 'Fours'];
                    break;
                case '8': // Best Batting Avg
                    dataKey = 'most_sixes';
                    headers = ['No.', 'Players', 'Matches', 'Innings', 'Runs', 'Sixes'];
                    break;
                case '9': // Best Batting Avg
                    dataKey = 'most_nineties';
                    headers = ['No.', 'Players', 'Matches', 'Innings', 'Runs', 'High Score', 'Nineties'];
                    break;
                default:
                    dataKey = 'most_runs';
                    headers = ['No.', 'Players', 'Matches', 'Innings', 'Runs', 'Strike Rate', 'Avg', 'Fours', 'Sixes'];
                    break;
            }
        } else {
            switch (subtype) {
                case '1': // Most Wickets
                    dataKey = 'most_wickets';
                    headers = ['No.', 'Players', 'Matches', 'Runs', 'Overs', 'Balls', 'Wickets', 'Economy'];
                    break;
                case '2': // Most 5-Wicket Hauls
                    dataKey = 'most_five_wickets';
                    headers = ['No.', 'Players', 'Matches', 'Runs', 'Overs', 'Balls', 'Wickets'];
                    break;
                case '3': // Best Bowling
                    dataKey = 'most_bowling';
                    headers = ['No.', 'Players', 'Runs', 'Overs', 'Balls', 'Wickets', 'BBI', 'Economy'];
                    break;
                case '4': // Best Bowling
                    dataKey = 'best_economy';
                    headers = ['No.', 'Players', 'Matches', 'Runs', 'Overs', 'Balls', 'Wickets', 'Economy'];
                    break;
                // Add more cases for different bowling subtypes...
                default:
                    dataKey = 'most_wickets';
                    headers = ['No.', 'Players', 'Matches', 'Runs', 'Overs', 'Balls', 'Wickets', 'Economy'];
                    break;
            }
        }

        setTableHeaders(headers);
        setStatsData(data[dataKey]); // Set the appropriate data based on the response
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setSubtype('1');
        fetchStatsData(tab, 1)
    };

    const handleSubtypeChange = (e) => {
        setSubtype(e.target.value); // Update selected subtype
        fetchStatsData(activeTab, e.target.value)
    };

    return (
        <div>
            <div className="section-title">
                <h5 className="mb-0">Stats</h5>
            </div>
            <div className="inner-tabs mb-20">
                <ul>
                    <li
                        className={activeTab === 1 ? 'active' : ''}
                        onClick={() => handleTabChange(1)}
                    >
                        Batting
                    </li>
                    <li
                        className={activeTab === 2 ? 'active' : ''}
                        onClick={() => handleTabChange(2)}
                    >
                        Bowling
                    </li>
                </ul>
            </div>
            <div className="cardBox mb-20 p-15">
                <div className="row align-items-center">
                    <div className="col-lg-8">
                        <div className="form-group">
                            <select className="custom-select" value={subtype} onChange={handleSubtypeChange}>
                                {activeTab === 1 ? (
                                    <>
                                        <option value="1">Most Runs</option>
                                        <option value="2">Highest Score</option>
                                        <option value="3">Best Batting Avg</option>
                                        <option value="4">Best Batting SR</option>
                                        <option value="5">Most Hundreds</option>
                                        <option value="6">Most Fifties</option>
                                        <option value="7">Most Fours</option>
                                        <option value="8">Most Sixes</option>
                                        <option value="9">Most Nineties</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="1">Most Wickets</option>
                                        <option value="2">Most 5-Wicket Hauls</option>
                                        <option value="3">Best Bowling</option>
                                        <option value="4">Best Economy</option>
                                    </>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="tx-right">
                            <p className="tx-12 tx-gray-500 mb-1">Last Update on</p>
                            <p className="mb-0 tx-12 tx-gray-500">{formattedDate}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stable-section">
                <table className="stable">
                    <thead>
                        <tr>
                            {tableHeaders.map((header, index) => (
                                <th key={index} className={header === 'Players' ? '' : 'text-center'}>
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            // Skeleton loader when loading data
                            Array(5).fill().map((_, index) => (
                                <tr key={index}>
                                    {tableHeaders.map((_, i) => (
                                        <td className="text-center" key={i}><Skeleton width={i === 1 ? 120 : 50} /></td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            statsData && statsData.length > 0 ? (
                                statsData.map((player, index) => (
                                    <tr key={index + 1}>
                                        <td className="text-center">{index + 1}</td>
                                        <td>{player.player}</td>
                                        {player.matches !== undefined && player.matches !== null && (
                                            <td className="text-center">{player.matches}</td>
                                        )}
                                        {player.inning !== undefined && player.inning !== null && (
                                            <td className="text-center">{player.inning}</td>
                                        )}
                                        {player.runs !== undefined && player.runs !== null && (
                                            <td className="text-center">{player.runs}</td>
                                        )}
                                        {player.overs !== undefined && player.overs !== null && (
                                            <td className="text-center">{player.overs}</td>
                                        )}
                                        {player.balls !== undefined && player.balls !== null && (
                                            <td className="text-center">{player.balls}</td>
                                        )}
                                        {player.high_score !== undefined && player.high_score !== null && (
                                            <td className="text-center">{player.high_score}</td>
                                        )}
                                        {player.hundreds !== undefined && player.hundreds !== null && (
                                            <td className="text-center">{player.hundreds}</td>
                                        )}
                                        {player.fifty !== undefined && player.fifty !== null && (
                                            <td className="text-center">{player.fifty}</td>
                                        )}
                                        {player.nineties !== undefined && player.nineties !== null && (
                                            <td className="text-center">{player.nineties}</td>
                                        )}
                                        {(player.sr || player.strike_rate) && (
                                            <td className="text-center">{player.sr || player.strike_rate}</td>
                                        )}
                                        {player.avg !== undefined && player.avg !== null && (
                                            <td className="text-center">{player.avg}</td>
                                        )}
                                        {player.fours !== undefined && player.fours !== null && (
                                            <td className="text-center">{player.fours}</td>
                                        )}
                                        {player.sixes !== undefined && player.sixes !== null && (
                                            <td className="text-center">{player.sixes}</td>
                                        )}
                                        {player.wkts !== undefined && player.wkts !== null && (
                                            <td className="text-center">{player.wkts}</td>
                                        )}
                                        {player.bbi !== undefined && player.bbi !== null && (
                                            <td className="text-center">{player.bbi}</td>
                                        )}
                                        {(player.eco || player.economy) && (
                                            <td className="text-center">{player.eco || player.economy}</td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8">No data available</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SeriesStats;

import moment from "moment";
// 29 Mar, 2025
export const formatPublishDate = (date) => {
    return moment(date).format('DD MMM YYYY');
};

export const formatPublishTime = (date) => {
    return moment(date).format('h:mm A');
};

export const formatPublishDateWithDay = (date) => {
    return moment(date).format('DD MMM YYYY, dddd');
};

export const formatPublishDateWithTime = (date) => {
    return moment(date).format('DD MMM YYYY, h:mm A');
};

export const formatTimeFromNow = (date) => {
    return moment(date).startOf('hour').fromNow();
};

// 11 Sep 2024, Wed, 12:44 PM IST
export const formatMatchWithTime = (date) => {
    return moment(date).format('DD MMM YYYY, ddd, h:mm A [IST]');
};
const MatchSquad = () => {
    return (
        <div>
            <div className="section-title">
                <h5 className="mb-0">Squad</h5>
            </div>
        </div>
    )
}

export default MatchSquad
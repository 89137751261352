import React, { createContext, useEffect, useRef, useState } from 'react';
import { ApiService } from '../Services/apiservices';
const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [settingData, setSettingData] = useState({});
    const [settingImageBaseUrl, setSettingImageBaseUrl] = useState('');
    const [toggleMenuModal, setToggleMenuModal] = useState(false);
    const [toggleSearchModal, setToggleSearchModal] = useState(false);
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            getSettingData()
        }
        didMountRef.current = false
    })

    const getSettingData = () => {
        ApiService.fetchData("settingData").then((res) => {
            if (res.status === "success") {
                setSettingData(res.themeAdminData)
            }
        })
    }
    return (
        <DataContext.Provider value={
            {
                settingData, setSettingData,
                settingImageBaseUrl, setSettingImageBaseUrl,
                toggleMenuModal, setToggleMenuModal,
                toggleSearchModal, setToggleSearchModal
            }
        }>
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;
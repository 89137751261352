import { useEffect, useRef, useState } from "react"
import { ApiService } from "../Services/apiservices"
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from "react-router-dom"
const CurrentSeries = () => {
    const didMountRef = useRef(true)
    const [seriesList, setSeriesList] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getSeriesData()
        }
        didMountRef.current = false
    }, [])

    const getSeriesData = () => {
        ApiService.fetchData("currentSeries").then((res) => {
            if (res.status === "success") {
                setSeriesList(res.currentSeries)
            }
            setLoading(false)
        })
    }
    

    const handleLink = (seriesId) => {
        navigate(`/series-detail/${seriesId}`);
    };
    return (
        <>
            {loading ? (
                <>
                    <div className="section-title">
                        <h6 className="mb-0"><Skeleton width={140} height={20} /></h6>
                        <a href="javascript:void(0)" className="viewallbutton"><Skeleton height={20} width={80} /></a>
                    </div>
                    <div className="cardBox mb-30">
                        <div className="currentserieslist">
                            <ul>
                                {[...Array(5)].map((_, index) => (
                                    <li key={index}><Skeleton width={160} height={15} /> </li>
                                ))}
                            </ul>
                        </div>
                    </div></>
            ) : (
                seriesList && seriesList.length > 0 && (
                    <>
                        <div className="section-title">
                            <h6 className="mb-0">Current Series</h6>
                            <a href="/series-list" className="viewallbutton">View All</a>
                        </div>
                        <div className="cardBox mb-30">
                            <div className="currentserieslist">
                                <ul>
                                    {seriesList.map((series, index) => (
                                        <li key={index} onClick={(e) => handleLink(series.series_slug)}>{series.series_title}<i className="ri-arrow-right-s-line"></i></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </>
                )
            )}
        </>
    )
}

export default CurrentSeries
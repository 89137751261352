import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'remixicon/fonts/remixicon.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css';
import Home from './Container/Home/home';
import Fixture from'./Container/Fixtures/index';
import Leaderboard from'./Container/Leaderboard/index';
import News from'./Container/News/index';
import PointTable from'./Container/PointTable/index';
import Schedule from'./Container/Schedule/index';
import Series from'./Container/Series/index';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Pages from './Container/Pages';
import { useEffect } from 'react';
import SeriesDetail from './Container/Series/series_detail';
import SeriesList from './Container/Series/series_list';
import MatchDetail from './Container/MatchDetail';
import PlayerList from './Container/Player/player_list';
import PlayerDetail from './Container/Player/player_detail';
import NewsDetail from './Container/News/news_detail';
import VenueDetail from './Component/Elements/venue_detail';
import ICCMenRankings from './Container/ICCRankings/mensrankings';
import ICCWomenRankings from './Container/ICCRankings/womenrankings';

function App() {
  useEffect(() => {
    const storedTheme = localStorage.getItem("selectedTheme"); 
    document.documentElement.setAttribute('data-theme', storedTheme ? storedTheme : 'light');
  }, []);
  return (
    <Router>
    <Routes>
      <Route path="/" activeClassName="active" element={<Home />} />
      <Route path="/fixtures" activeClassName="active" element={<Fixture />} />
      <Route path="/leaderboard" activeClassName="active" element={<Leaderboard />} />
      <Route path="/icc-rankings/mens-ranking?" activeClassName="active" element={<ICCMenRankings />} />
      <Route path="/icc-rankings/womens-ranking?" activeClassName="active" element={<ICCWomenRankings />} />
      {/* News */}
      <Route path="/news" activeClassName="active" element={<News />} />
      <Route path="/news-detail/:news_id" activeClassName="active" element={<NewsDetail />} />
      {/* End */}

      <Route path="/point-table" activeClassName="active" element={<PointTable />} />
      <Route path="/schedule" activeClassName="active" element={<Schedule />} />
      
      <Route path="/match-detail/:match_id" activeClassName="active" element={<MatchDetail />} />

      {/* Series */}
      <Route path="/series" activeClassName="active" element={<Series />} />
      <Route path="/series-list" activeClassName="active" element={<SeriesList />} />
      <Route path="/series-detail/:series_id" activeClassName="active" element={<SeriesDetail />} />
      <Route path="/venue-detail/:venue_id" activeClassName="active" element={<VenueDetail />} />
      {/* End */}

      {/* Player */}
      <Route path="/players" activeClassName="active" element={<PlayerList />} />
      <Route path="/player-detail/:player_id" activeClassName="active" element={<PlayerDetail />} />
      {/* End */}

      <Route exact path='/:slug' activeClassName="active" element={<Pages />} />
    </Routes>
  </Router>
  );
}

export default App;

import { BrowserView, MobileView } from 'react-device-detect';
import BottomHeader from './bottom_header';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import DataContext from '../Elements/event_context';
import MenuSidebar from '../Modal/menu_sidebar';
import SearchModal from '../Modal/search_modal';
const Header = () => {
    const contextValues = useContext(DataContext);
    const location = useLocation();
    const [theme, setTheme] = useState('light');
    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem("selectedTheme", newTheme);
    };
    useEffect(() => {
        const storedTheme = localStorage.getItem("selectedTheme");
        if (storedTheme) {
            setTheme(storedTheme);
        }
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    const toggleMenuModal = () => {
        contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
    }

    const toggleSearchModal = () => {
        contextValues.setToggleSearchModal(!contextValues.toggleSearchModal)
    }
    return (
        <>
            <BrowserView>
                <header className="header">
                    <div className="header-middle">
                        <div className="container">
                            <div className="header-left">
                                <a href="/" className="logo">
                                    <img src="/img/logo.png" alt="logo" width={120} height={70}></img>
                                </a>
                            </div>
                            <div className="header-center">
                                <nav className="navbar navbar-expand-lg navbar-light">
                                    <ul className="navbar-nav mx-auto">
                                        <li className="nav-item">
                                            <a className={location.pathname === "/" ? "nav-link active" : "nav-link"} href="/">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={location.pathname.includes("/fixtures") ? "nav-link active" : "nav-link"} href="/fixtures">Fixtures</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={location.pathname.includes("/series") ? "nav-link active" : "nav-link"} href="/series-list">Series</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={location.pathname.includes("/schedule") ? "nav-link active" : "nav-link"} href="/schedule">Schedule</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={location.pathname.includes("/point-table") ? "nav-link active" : "nav-link"} href="/point-table">Point Table</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={location.pathname.includes("/news") ? "nav-link active" : "nav-link"} href="/news">News</a>
                                        </li> 
                                        <li className="nav-item dropdown dropdown-hover">
                                            <a className={location.pathname.includes("/icc-rankings") ? "nav-link active" : "nav-link"} href="javascript:void(0)" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                ICC Rankings
                                            </a>
                                            <ul className="dropdown-menu submenu" aria-labelledby="navbarDropdown">
                                                <li><a className="dropdown-item" href="/icc-rankings/mens-ranking">Men's Ranking</a></li>
                                                <li><a className="dropdown-item" href="/icc-rankings/womens-ranking">Women's Ranking</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a className={location.pathname.includes("/leaderboard") ? "nav-link active" : "nav-link"} href="/leaderboard">Leaderboard</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="header-right justify-content-end">
                                <ul className="rightlist">
                                    <li className="search-icon" onClick={(e) => toggleSearchModal()}><i className="ri-search-line"></i></li>
                                    <li className="dlmode" onClick={(e) => toggleTheme()}>
                                        {theme === 'light' ? (
                                            <>
                                                <i className="ri-moon-line mr-5"></i> <span className="dlmode-name">Dark</span>
                                            </>
                                        ) : (
                                            <>
                                                <i className="ri-sun-line mr-5"></i> <span className="dlmode-name">Light</span>
                                            </>
                                        )} </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <BottomHeader />
                </header>
            </BrowserView>
            <MobileView>
                <header className="header">
                    <div className="header-middle">
                        <div className="container">
                            <div className="header-left">
                                <a href='javascript:void(0)' className='sidenav-icon' onClick={(e) => toggleMenuModal()}><i className="ri-menu-2-line"></i></a>
                                <a href="/" className="logo">
                                    <img src="/img/logo.png" alt="logo" width={120} height={70}></img>
                                </a>
                            </div>

                            <div className="header-right justify-content-end">
                                <ul className="rightlist">
                                    <li className="search-icon" onClick={(e) => toggleSearchModal()}><i className="ri-search-line"></i></li>
                                    <li className="dlmode" onClick={(e) => toggleTheme()}>
                                        {theme === 'light' ? (
                                            <>
                                                <i className="ri-moon-line"></i><span className="dlmode-name">Dark</span>
                                            </>
                                        ) : (
                                            <>
                                                <i className="ri-sun-line"></i> <span className="dlmode-name">Light</span>
                                            </>
                                        )} </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <BottomHeader />
                </header>
                {contextValues.toggleMenuModal && (<MenuSidebar />)}

            </MobileView>
            {contextValues.toggleSearchModal && (<SearchModal />)}
        </>
    )
}

export default Header
import React, { useEffect, useRef, useState } from 'react'
import Skeleton from "react-loading-skeleton";
import { ApiService } from '../Services/apiservices';
import constant from '../Services/constant';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
function UpcomingMatches() {
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    const [matchData, setMatchData] = useState([])
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (didMountRef.current) {
            getupcomingmatches()
        }
        didMountRef.current = false
    })

    const getupcomingmatches = () => {
        ApiService.fetchData("upcomingmatches").then((res) => {
            if (res.status === "success") {
                setMatchData(res.matchData)
            }
            setLoading(false);
        })
    }
    const formatPublishDate = (date) => {
        const format = 'DD MMM YYYY, dddd';
        const formattedDate = moment(date, format).format('DD MMM YYYY');
        return formattedDate;
    }
    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };
    const handleLink = (matchId) => {
        navigate(`/match-detail/${matchId}`);
    };
    return (
        <>
            {loading ? (
                <>
                    <div className="section-title">
                        <h6 className="mb-0"><Skeleton width={150} height={20} /></h6>
                        <a href="javascript:void(0)" className="viewallbutton"><Skeleton width={60} height={20} /></a>
                    </div>
                    <div className="mb-30">
                        {[...Array(5)].map((_, index) => (
                            <div className="hmatchbox mb-10" key={index}>
                                <div className="hmatchbox-left">
                                    <div className="hmtmatch">
                                        <Skeleton circle width={40} height={40} />
                                        <div className="hmtmatchContent">
                                            <h6 className="hmtmatch-name"><Skeleton width={30} height={15} /></h6>
                                        </div>
                                    </div>
                                    <div className="hmatchbox-center">
                                        <p className="mb-0 tx-gray-500"><Skeleton width={50} height={15} /></p>
                                    </div>
                                    <div className="hmatchbox-right justify-content-end">
                                        <div className="hmtmatch">
                                            <div className="hmtmatchContent tx-right">
                                                <h6 className="hmtmatch-name"><Skeleton width={30} height={15} /></h6>
                                            </div>
                                            <Skeleton circle width={40} height={40} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                matchData && matchData.length > 0 && (
                    <>
                        <div className="section-title">
                            <h6 className="mb-0">Upcoming Matches</h6>
                            <a href="/fixtures" className="viewallbutton">View All</a>
                        </div>
                        <div className="mb-30">
                            {matchData.map((match, index) => (
                                <div className="hmatchbox mb-10" onClick={() => handleLink(match.match_id)} key={index}>
                                    <div className="hmatchbox-left">
                                        <div className="hmtmatch">
                                            <div className="hmtmatchMedia">
                                                <img src={match.match_teama_image} alt={match.match_teama_shortname} onError={handleImageError}></img>
                                            </div>
                                            <img src={match.match_teama_image} className="bimg" alt={match.match_teama_shortname} onError={handleImageError}></img>
                                            <div className="hmtmatchContent">
                                                <h6 className="hmtmatch-name">{match.match_teama_shortname}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hmatchbox-center">
                                        <p className="mb-0 tx-gray-500 tx-12">{formatPublishDate(match.match_date_wise)}</p>
                                    </div>
                                    <div className="hmatchbox-right justify-content-end">
                                        <div className="hmtmatch">
                                            <img src={match.match_teamb_image} className="cimg" alt={match.match_teamb_shortname} onError={handleImageError}></img>
                                            <div className="hmtmatchContent tx-right">
                                                <h6 className="hmtmatch-name">{match.match_teamb_shortname}</h6>
                                            </div>
                                            <div className="hmtmatchMedia">
                                                <img src={match.match_teamb_image} alt={match.match_teamb_shortname} onError={handleImageError}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )
            )}
        </>
    )
}

export default UpcomingMatches

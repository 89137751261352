import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Component/Services/apiservices";
import Skeleton from 'react-loading-skeleton'; // Import the skeleton loader
import constant from "../../Component/Services/constant";
import { Breadcrumb } from 'react-bootstrap'
import TrendingPlayer from "../../Component/Elements/trending_player";
import CurrentSeries from "../../Component/Elements/current_series";
import LatestNews from "../../Component/Elements/latest_news";

const PlayerDetail = () => {
    const { player_id } = useParams();
    const [playerData, setPlayerData] = useState({});
    const [battingCareer, setBattingCareer] = useState([]);
    const [BowlingCareer, setBowlingCareer] = useState([]);
    const [DBplayerData, setDBplayerData] = useState({});
    const [Team, setTeam] = useState();
    const [loading, setLoading] = useState(true);

    const didMountRef = useRef(true);

    useEffect(() => {
        if (didMountRef.current) {
            getplayerDetail();
            getplayerDetailDB();
            didMountRef.current = false;
        }
    }, []);

    const getplayerDetail = () => {
        setLoading(true);
        // Create a new FormData object and append the necessary data
        const formData = new FormData();
        formData.append('player_id', player_id);
        ApiService.postCricketData("playerInfo/", formData)
            .then((res) => {
                if (res.status) {
                    setPlayerData(res.data.player);
                    setTeam(res.data.teams);
                    setBattingCareer(res.data.batting_career);
                    setBowlingCareer(res.data.bowling_career);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const getplayerDetailDB = () => {
        // Create a new FormData object and append the necessary data
        const formData = new FormData();
        formData.append('player_api_id', player_id);
        ApiService.postData("playersDetail/", formData)
            .then((res) => {
                if (res.status == 'success') {
                    setDBplayerData(res.playersDetail);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    return (
        <>
            <Header />
            <div className="pageSubheader">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active href="/">{loading ? <Skeleton height={15} width={200} /> : playerData?.name}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
        </div>
            <section className="section-gap-small pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="player-detailsbox mb-30">
                                <div className="player-detailsbox-media">
                                    {loading ? (
                                        <Skeleton circle={true} height={120} width={120} />
                                    ) : (
                                        <img src={playerData?.image} alt={playerData?.name} />
                                    )}
                                </div>
                                <div className="player-detailsbox-content">
                                    <h2 className="player-name">{loading ? <Skeleton width={200} /> : playerData?.name}</h2>
                                    <ul className="player-meta">
                                        <li>
                                            <img src={DBplayerData && DBplayerData.player_team_flag ? DBplayerData?.player_team_flag : constant.DEFAULT_IMAGE} alt="Team" />
                                            {loading ? <Skeleton width={100} /> : Team || '-----'}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="section-title">
                                <h5 className="mb-0">Players Info</h5>
                            </div>
                            <div className="cardBox mb-30">
                                <div className="pdinfo">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <p className="tx-gray-500 mb-0">Full Name</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="fw700 mb-0">
                                                {loading ? <Skeleton width={150} /> : playerData?.name}
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <p className="tx-gray-500 mb-0">Nationality</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="fw700 mb-0">
                                                {loading ? <Skeleton width={100} /> : "-----"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pdinfo">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <p className="tx-gray-500 mb-0">Gender</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="fw700 mb-0">
                                                {loading ? <Skeleton width={100} /> : DBplayerData?.player_gender}
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <p className="tx-gray-500 mb-0">Role</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="fw700 mb-0">
                                                {loading ? <Skeleton width={100} /> : playerData?.play_role}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pdinfo">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <p className="tx-gray-500 mb-0">Birth Date</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="fw700 mb-0">
                                                {loading ? <Skeleton width={150} /> : playerData?.born}
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <p className="tx-gray-500 mb-0">Batting Style</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="fw700 mb-0">
                                                {loading ? <Skeleton width={150} /> : playerData?.style_bating}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pdinfo">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <p className="tx-gray-500 mb-0">Birth Place</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="fw700 mb-0">
                                                {loading ? <Skeleton width={150} /> : playerData?.birth_place}
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <p className="tx-gray-500 mb-0">Bowling Style</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="fw700 mb-0">
                                                {loading ? <Skeleton width={150} /> : playerData?.style_bowling}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pdinfo">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <p className="tx-gray-500 mb-0">Height</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="fw700 mb-0">
                                                {loading ? <Skeleton width={100} /> : playerData?.height}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pdinfo">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <p className="fw700 mb-0">Socials</p>
                                        </div>
                                        <div className="col-lg-10">
                                        <ul className="footer-social">
                                {DBplayerData.player_facebook && (
                                    <li>
                                        <a href={DBplayerData.player_facebook} target="_blank">
                                            <i className="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                )}
                                {DBplayerData.player_insta && (
                                    <li>
                                        <a href={DBplayerData.player_insta} target="_blank">
                                            <i className="ri-instagram-line"></i>
                                        </a>
                                    </li>
                                )}
                                {DBplayerData.player_twitter && (
                                    <li>
                                        <a href={DBplayerData.player_twitter} target="_blank">
                                            <i className="ri-twitter-x-line"></i>
                                        </a>
                                    </li>
                                )}
                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="pdinfo noborder">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <p className="fw700 mb-0">Teams</p>
                                        </div>
                                        <div className="col-lg-10">
                                            {/* Add team info if available */}
                                        </div>
                                    </div>
                                </div>
                                <div className="pdinfo noborder">
                                    <div className="row align-items-start">
                                        <div className="col-lg-2">
                                            <p className="fw700 mb-0">About</p>
                                        </div>
                                        <div className="col-lg-10">
                                            <p className="mb-0">
                                                {loading ? (
                                                    <Skeleton count={3} />
                                                ) : (
                                                    <span dangerouslySetInnerHTML={{ __html: playerData?.description || "-----" }} />
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-title">
                                <h5 className="mb-0">Batting</h5>
                            </div>
                            <div className="pdtable-section mb-4">
                                <table className="pdtable">
                                    <thead>
                                        <tr>
                                            <th>Match Type</th>
                                            <th className="text-center">Match</th>
                                            <th className="text-center">Inns</th>
                                            <th className="text-center">Avg</th>
                                            <th className="text-center">SR</th>
                                            <th className="text-center">4s</th>
                                            <th className="text-center">6s</th>
                                            <th className="text-center">Runs</th>
                                            <th className="text-center">Balls</th>
                                            <th className="text-center">HS</th>
                                            <th className="text-center">NO</th>
                                            <th className="text-center">Ducks</th>
                                            <th className="text-center">50s</th>
                                            <th className="text-center">100s</th>
                                            <th className="text-center">200s</th>
                                            <th className="text-center">300s</th>
                                            <th className="text-center">400s</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td ><Skeleton height={15} width={70} /></td>
                                                {[...Array(16)].map((_, index) => (
                                                    <td className="text-center"><Skeleton height={15} width={20} /></td>
                                                ))}
                                            </tr>
                                        ) : (
                                            battingCareer && battingCareer.length>0 && battingCareer.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.match_type}</td>
                                                    <td className="text-center">{data.matches || '-'}</td>
                                                    <td className="text-center">{data.inning || '-'}</td>
                                                    <td className="text-center">{data.avg || '-'}</td>
                                                    <td className="text-center">{data.sr || '-'}</td>
                                                    <td className="text-center">{data.fours || '-'}</td>
                                                    <td className="text-center">{data.sixes || '-'}</td>
                                                    <td className="text-center">{data.runs || '-'}</td>
                                                    <td className="text-center">{data.balls || '-'}</td>
                                                    <td className="text-center">{data.high_score || '-'}</td>
                                                    <td className="text-center">{data.not_out || '-'}</td>
                                                    <td className="text-center">{data.ducks || '-'}</td>
                                                    <td className="text-center">{data.fifty || '-'}</td>
                                                    <td className="text-center">{data.hundreds || '-'}</td>
                                                    <td className="text-center">{data.two_hundreds || '-'}</td>
                                                    <td className="text-center">{data.three_hundreds || '-'}</td>
                                                    <td className="text-center">{data.four_hundreds || '-'}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="section-title">
                                <h5 className="mb-0">Bowling</h5>
                            </div>
                            <div className="pdtable-section">
                                <table className="pdtable">
                                    <thead>
                                        <tr>
                                            <th>Match Type</th>
                                            <th className="text-center">Match</th>
                                            <th className="text-center">Inns</th>
                                            <th className="text-center">Wickets</th>
                                            <th className="text-center">BBI</th>
                                            <th className="text-center">Eco</th>
                                            <th className="text-center">Avg</th>
                                            <th className="text-center">SR</th>
                                            <th className="text-center">4W</th>
                                            <th className="text-center">5W</th>
                                            <th className="text-center">10W</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td ><Skeleton height={15} width={70} /></td>
                                                {[...Array(10)].map((_, index) => (
                                                    <td className="text-center"><Skeleton height={15} width={30} /></td>
                                                ))}
                                            </tr>
                                        ) : (
                                            BowlingCareer && BowlingCareer.length>0 && BowlingCareer.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.match_type}</td>
                                                    <td className="text-center">{data.matches || '-'}</td>
                                                    <td className="text-center">{data.inning || '-'}</td>
                                                    <td className="text-center">{data.wkts || '-'}</td>
                                                    <td className="text-center">{data.bbi || '-'}</td>
                                                    <td className="text-center">{data.economy || '-'}</td>
                                                    <td className="text-center">{data.avg || '-'}</td>
                                                    <td className="text-center">{data.sr || '-'}</td>
                                                    <td className="text-center">{data.four_wkt || '-'}</td>
                                                    <td className="text-center">{data.five_wkt || '-'}</td>
                                                    <td className="text-center">{data.ten_wkt || '-'}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-3">
                        <div className="seriesSidebar">
                            <TrendingPlayer />
                            <CurrentSeries />
                            <LatestNews />
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default PlayerDetail;

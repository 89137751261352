import Footer from "../../Component/Footer"
import Header from "../../Component/Header"

const Leaderboard=()=>{
    return(<>
    <Header></Header>

    <Footer></Footer>
    </>)
}

export default Leaderboard
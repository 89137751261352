const MatchLiveLine = () => {
    return (
        <div>
            <div className="section-title">
                <h5 className="mb-0">Live Line</h5>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="cardBox mb-30">
                        <div className="winingpos">
                            <div>
                                <h6 className="mb-0 tx-primary">Winning Possibility</h6>
                            </div>
                            <div class="d-flex align-items-start gap-2">
                                <span class="tx-primary tx-10">Fav</span>
                                <span class="fw700">WOL</span>
                                <span class="back bhv">1.35</span>
                                <span class="lay bhv">1.35</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="cardBox mb-30">
                        <div className="wininlayb">
                            <span className="run">-Run</span>
                            <span className="ball">-Ball</span>
                            <span className="over">0 Over</span>
                            <span className="lay">3.1</span>
                            <span className="back">2.1</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="score-table-section mb-15">
                        <table className="score-table">
                            <thead>
                                <tr>
                                    <th className="tx-primary">Bastman</th>
                                    <th class="text-center">R</th>
                                    <th class="text-center">R</th>
                                    <th class="text-center">R</th>
                                    <th class="text-center">R</th>
                                    <th class="text-center">R</th>
                                 
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MatchLiveLine
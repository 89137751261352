import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Component/Header'
import Footer from '../../Component/Footer'
import { ApiService } from '../../Component/Services/apiservices'
import InfiniteScroll from 'react-infinite-scroll-component'
import { formatPublishDate } from '../../Component/Services/moment'
import constant from '../../Component/Services/constant'
import TrendingPlayer from '../../Component/Elements/trending_player'
import CurrentSeries from '../../Component/Elements/current_series'
import LatestNews from '../../Component/Elements/latest_news'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { Breadcrumb } from 'react-bootstrap'
function SeriesList() {
    const didMountRef = useRef(true)
    const [seriesData, setSeriesData] = useState(null);
    const [seriesList, setSeriesList] = useState([])
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        if (didMountRef.current) {
            getSeriesData()
        }
        didMountRef.current = false
    }, [])

    const getSeriesData = () => {
        ApiService.fetchData("series/seriesList").then((res) => {
            if (res.status === "success") {
                setSeriesData(res.seriesList)
                setSeriesList((prevNewList) => [...prevNewList, ...res.seriesList.data]);
                setHasMore(res.seriesList.current_page < res.seriesList.last_page);
            }
            setLoading(false)
        })

    }

    const fetchMoreData = () => {
        if (seriesData.current_page < seriesData.last_page) {
            getSeriesData(seriesData.current_page + 1);
        }
    };
    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };
    const handleLink = (seriesId) => {
        navigate(`/series-detail/${seriesId}`);
    };
    return (
        <>
            <Header />
            <div className="pageSubheader">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Breadcrumb>
                                {loading ?
                                    <>
                                        <Breadcrumb.Item href="/"><Skeleton width={40} height={15} /></Breadcrumb.Item>
                                        <Breadcrumb.Item active href="/"><Skeleton width={100} height={15} /></Breadcrumb.Item>
                                    </>
                                    :
                                    <>
                                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                        <Breadcrumb.Item active href="/">Series List</Breadcrumb.Item>
                                    </>

                                }
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-gap-small pt-0">
                <div className="container">
                    {loading ? (
                        <>
                            <div className="row">
                                <div className="col-lg-9">
                                    {Array.from({ length: 5 }).map((_, index) => (
                                        <div className="cardBox mb-20" key={index}>
                                            <div className="cardBoxBody p-25">
                                                <div className="seriesBox">
                                                    <figure className="seriesBoxMedia">
                                                        <Skeleton width={150} height={200} />
                                                    </figure>
                                                    <div className="seriesBoxContent">
                                                        <p className="mb-1"><Skeleton width={150} height={15} /></p>
                                                        <h2 className="title"><Skeleton width={500} height={25} /></h2>
                                                        <div className="seriesBoxMeta">
                                                            <ul>
                                                                <li><Skeleton width={70} height={15} /></li>
                                                                <li><Skeleton width={70} height={15} /></li>
                                                                <li><Skeleton width={70} height={15} /></li>
                                                            </ul>
                                                        </div>
                                                        <div className="seriesBox-date">
                                                            <Skeleton width={150} height={15} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-lg-3">
                                    <div className="seriesSidebar">
                                        <TrendingPlayer />
                                        <CurrentSeries />
                                        <LatestNews />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-lg-9">
                                    <InfiniteScroll
                                        dataLength={seriesList.length}
                                        next={fetchMoreData}
                                        hasMore={hasMore}
                                        loader={<h4>Loading...</h4>}
                                    >
                                        {seriesList.map((seriesDetail, index) => (
                                            <div className="cardBox mb-20" onClick={(e) => handleLink(seriesDetail.series_slug)} key={index}>
                                                <div className="cardBoxBody p-25">
                                                    <div className="seriesBox">
                                                        <figure className="seriesBoxMedia">
                                                            <img src={seriesDetail.series_image} alt={seriesDetail.series_title} onError={handleImageError}></img>
                                                        </figure>
                                                        <div className="seriesBoxContent">
                                                            <p className="mb-1">{seriesDetail.series_type}</p>
                                                            <h2 className="title">{seriesDetail.series_title}</h2>
                                                            <div className="seriesBoxMeta">
                                                                <ul>
                                                                    {seriesDetail.series_matches && (<li>{seriesDetail.series_matches} Matches</li>)}
                                                                    {seriesDetail.series_players && seriesDetail.series_players.data && (<li>{seriesDetail.series_players.data.length} Teams</li>)}
                                                                </ul>
                                                            </div>
                                                            <div className="seriesBox-date">
                                                                {formatPublishDate(seriesDetail.series_start_date)} -{' '}
                                                                {formatPublishDate(seriesDetail.series_end_date)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </InfiniteScroll>
                                </div>
                                <div className="col-lg-3">
                                    <div className="seriesSidebar">
                                        <TrendingPlayer />
                                        <CurrentSeries />
                                        <LatestNews />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>
            <Footer />
        </>
    )
}

export default SeriesList

import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import { ApiService } from '../Services/apiservices';
const Ranking = () => {
    const [key, setKey] = useState('1');
    const didMountRef = useRef(true)
    const [teamRankingList, setTeamRankingList] = useState([])
    const [activeHeadingKey, setActiveHeadingKey] = useState('1');
    const [activeSubHeadingKey, setActiveSubHeadingKey] = useState('1');

    const headingList = {
        1: 'Teams',
        2: 'Batting',
        3: 'Bowling',
        4: 'All Rounders'
    };

    const subHeadingList = {
        1: 'ODI',
        2: 'Test',
        3: 'T20'
    };
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (didMountRef.current) {
            getTeamRankingData()
        }
        didMountRef.current = false
    }, [])

    const getTeamRankingData = () => {
        const formData = new FormData();
        formData.append('type', 1);
        ApiService.postCricketData("teamRanking/", formData).then((res) => {
            if (res.status) {
                setLoading(false);
                setTeamRankingList(res.data);
            }
        })
    }
    const ChangeHeadingsTab = (k) => {
        const formData = new FormData();
        let endpoint = "";
        if (k == 1) {
            endpoint = "teamRanking/";
            formData.append('type', k);
        } else {
            endpoint = "playerRanking/";
            formData.append('type', k == 2 ? 4 : k == 3 ? 5 : 6);
        }

        ApiService.postCricketData(endpoint, formData).then((res) => {
            if (res.status) {
                setTeamRankingList(res.data);
                setActiveHeadingKey(k);
                setActiveSubHeadingKey('1');
            }
        });
    };


    const ChangeSubHeadingsTab = (k) => {
        const formData = new FormData();
        let endpoint = "";

        if (activeHeadingKey == 1) {
            endpoint = "teamRanking/";
            formData.append('type', k);
        } else {
            let val = 0;
            endpoint = "playerRanking/";
            if (activeHeadingKey == 2) {
                if (k == 1) { val = 4 }
                else if (k == 2) { val = 1 }
                else if (k == 3) { val = 7 }
            } else if (activeHeadingKey == 3) {
                if (k == 1) { val = 5 }
                else if (k == 2) { val = 2 }
                else if (k == 3) { val = 8 }
            } else if (activeHeadingKey == 4) {
                if (k == 1) { val = 6 }
                else if (k == 2) { val = 3 }
                else if (k == 3) { val = 9 }
            }
            formData.append('type', val);
        }

        ApiService.postCricketData(endpoint, formData).then((res) => {
            if (res.status) {
                setTeamRankingList(res.data);  // Renamed setTeamRankingList to setRankingList for general use
                setActiveSubHeadingKey(k);
            }
        });
    }
    return ( 
            <>
                <div className="section-title">
                    <h6 className="mb-0">Ranking</h6>
                    <a href="/icc-rankings/mens-ranking" className="viewallbutton">View All</a>
                </div>
                <div className="cardBox mb-30">
                    <div className="rankingtabs">
                        <ul>
                            {Object.keys(headingList).map((key) => (
                                <li
                                    key={key}
                                    className={activeHeadingKey === key ? "active" : ""}
                                    onClick={() => ChangeHeadingsTab(key)}
                                >
                                    {headingList[key]}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="rankingtabs-inner">
                        <ul>
                            {Object.keys(subHeadingList).map((subKey) => (
                                <li
                                    key={subKey}
                                    className={activeSubHeadingKey === subKey ? "active" : ""}
                                    onClick={() => ChangeSubHeadingsTab(subKey)}
                                >
                                    {subHeadingList[subKey]}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <table className="ranktable">
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>{teamRankingList.length && teamRankingList[0].team ? "Team" : "Player"}</th>
                                <th className="tx-right">Rating</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teamRankingList && teamRankingList.length > 0 ? (
                                teamRankingList.slice(0, 5).map((value, index) => (
                                    <tr key={index}>
                                        <td>{value.rank}</td>
                                        <td>{value.team || value.name}</td>
                                        <td className="tx-right">{value.rating}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={3}>No Record Found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
         
    )
}

export default Ranking
import { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataContext from '../Elements/event_context';
const MenuSidebar = () => {
    const contextValues = useContext(DataContext);
    const toggleMenuModal = () => {
        contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
    }
    const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

    const toggleSubMenu = () => {
        setIsSubMenuVisible(!isSubMenuVisible);
    };

    return (<>
        <Modal className='menuModal left' show={contextValues.toggleMenuModal} onHide={toggleMenuModal}>
            <div className='menuModal-header'>
                <a href='/' className='menu-logo'>
                    <img src='/img/logo.png'></img>
                </a>
                <button type='button' className='close-button' onClick={(e) => toggleMenuModal()}><i className="ri-close-line ri-2x"></i></button>
            </div>
            <div className='menuModal-body'>
                <ul className='menu-list'>
                    <li>
                        <a href="/"><span>Home</span><i className="ri-arrow-right-s-line ri-lg"></i></a>
                    </li>
                    <li>
                        <a href="/fixtures"><span>Fixtures</span><i className="ri-arrow-right-s-line ri-lg"></i></a>
                    </li>
                    <li>
                        <a href="/series-list"><span>Series</span><i className="ri-arrow-right-s-line ri-lg"></i></a>
                    </li>
                    <li>
                        <a href="/schedule"><span>Schedule</span><i className="ri-arrow-right-s-line ri-lg"></i></a>
                    </li>
                    <li>
                        <a href="/point-table"><span>Point Table</span><i className="ri-arrow-right-s-line ri-lg"></i></a>
                    </li>
                    <li>
                        <a href="/news"><span>News</span><i className="ri-arrow-right-s-line ri-lg"></i></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={toggleSubMenu}><span>ICC Rankings</span><i className="ri-arrow-right-s-line ri-lg"></i></a>
                        {isSubMenuVisible && (
                            <ul>
                                <li><a href="/icc-rankings/mens-ranking">Men's Ranking</a></li>
                                <li><a href="/icc-rankings/womens-ranking">Women's Ranking</a></li>
                            </ul>
                        )}
                    </li> 
                    <li>
                        <a href="/leaderboard"><span>Leaderboard</span><i className="ri-arrow-right-s-line ri-lg"></i></a>
                    </li>
                </ul>
            </div>
        </Modal>
    </>)
}

export default MenuSidebar
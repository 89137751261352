import Footer from "../../Component/Footer"
import Header from "../../Component/Header"

const Schedule=()=>{
    return(<>
    <Header></Header>

    <Footer></Footer>
    </>)
}

export default Schedule
import { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';

const MatchBallByBall = () => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <div className="section-title">
                <h5 className="mb-0">Ball By Ball</h5>
            </div>
            <div className="cardBox p-15 mb-20">
                <div className="inner-tabs">
                    <ul>
                        <li className="active">1 Innings</li>
                        <li>2 Innings</li>
                    </ul>
                </div>
            </div>
            <div className="oddsbox">
                <div className="oddsbox-header"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                >
                    <div className="odleft">36 Over <span className="text-success">10 Runs</span></div>
                    <i className="ri-arrow-down-circle-fill ri-2x"></i>
                    <div className="odright">Markhors <span className="text-success">211-4</span></div>
                </div>
                <Collapse className='oddsbox-body' in={open}>
                    <div className="oddinnerbox">
                        <div className='oddinnerleft'>
                            <div className='d-flex gap-1 align-items-end mb-2'>
                                <span className='tx-primary tx-10'>Time</span>
                                <span className='tx-12'>06:17:00 PM</span>
                            </div>
                            <div className='d-flex align-items-center gap-3'>
                                <span className='over'>2</span>
                                <span className='fw700'>203/4</span>
                                <span className='tx-primary'>Runs</span>
                                <span>35.1</span>
                            </div>
                        </div>
                        <div className='oddinnerright'>
                            <div className='d-flex align-items-start gap-2 mb-2'>
                            <span className='tx-primary tx-10'>Fav</span>
                            <span className='fw700'>WOL</span>
                            <span className='back bhv'>1.35</span>
                            <span className='lay bhv'>1.35</span>
                            </div>
                            <div className='d-flex align-items-center justify-content-end gap-2'>
                            <span className='tx-primary tx-10'>Session</span>
                            <span className='session bhv'>0</span>
                            <span className='session bhv'>0</span>
                            </div>
                        </div>
                    </div>
                </Collapse>

            </div>
        </div>
    )
}

export default MatchBallByBall
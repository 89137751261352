import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { useEffect, useRef, useState } from "react"
import constant from "../../Component/Services/constant"
import { ApiService } from "../../Component/Services/apiservices"
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from "react-router-dom"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import InfiniteScroll from 'react-infinite-scroll-component'; // Import the infinite scroll component
import { Swiper, SwiperSlide } from 'swiper/react';


const PlayerList = () => {
    const didMountRef = useRef(true)
    const [playersList, setPlayersList] = useState([])
    const [TrendingplayersList, setTrendingPlayersList] = useState([])
    const [SearchPlayerList, setSearchPlayerList] = useState([])
    const [TeamList, setTeamList] = useState([])
    const [loading, setLoading] = useState(true)
    const [trendingloading, setTrendingLoading] = useState(true)
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1); // Track current page
    const [nationality, setNationality] = useState('');
    const [gender, setGender] = useState('');
    const [role, setRole] = useState('');
    const [isInternational, setIsInternational] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        if (didMountRef.current) {
            trendingPlayers()
            teamList()
            fetchPlayersList(page, { nationality, gender, role, isInternational, isActive })
        }
        didMountRef.current = false
    }, [])

    const fetchPlayersList = (page, filters = {}) => {
        const { nationality, gender, role, isInternational, isActive } = filters;

        const queryParams = new URLSearchParams({
            page,
            nationality,
            gender,
            role,
            international: isInternational ? 1 : 0,
            active: isActive ? 1 : 0
        }).toString();
        setLoading(true);
        ApiService.fetchData(`playersList?${queryParams}`).then((res) => {
            if (res.status === "success") {
                setPlayersList(prevPlayers => [...prevPlayers, ...res.playerData.data]);
                setLoading(false);
                setHasMore(res.playerData.current_page < res.playerData.last_page);
            }
        });
    };

    const handleFilterChange = (nationality, gender, role, isInternational, isActive) => {
        setPage(1);
        setPlayersList([]);

        fetchPlayersList(1, { nationality, gender, role, isInternational, isActive });
    };


    const searchPlayers = (query) => {
        if (query.length > 2) {
            ApiService.fetchData(`searchPlayers/${query}`).then((res) => {
                if (res.status === "success") {
                    setSearchPlayerList(res.playerData)
                }
            });
        } else {
            setSearchPlayerList([])
        }
    };

    const trendingPlayers = () => {
        ApiService.fetchData("trendingPlayers/all").then((res) => {
            if (res.status === "success") {
                setTrendingPlayersList(res.playerData)
            }
            setTrendingLoading(false)
        })
    }

    const teamList = () => {
        ApiService.fetchData("teamList").then((res) => {
            if (res.status === "success") {
                setTeamList(res.teamData)
            }
        })
    }

    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };

    const handleLink = (playerId) => {
        navigate(`/player-detail/${playerId}`);
    };

    return (<>
        <Header></Header>

        <section className="section-gap-small bg-gradient07">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="pageSubheader pt-0 pb-1">
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item >All Players</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="section-title">
                            <h2>Top Players</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="players-slider mb-20">
                            <Swiper spaceBetween={15}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    992: {
                                        slidesPerView: 6,
                                    },
                                }}
                            >
                                {trendingloading ? (
                                    <>
                                        {[...Array(6)].map((_, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="topplayerbox" key={index}>
                                                    <div className="topplayerbox-media">
                                                        <Skeleton height={100} />
                                                    </div>
                                                    <div className="topplayerbox-content">
                                                        <h2 className="player-name"><Skeleton height={20} width={100} /></h2>
                                                        <div className="player-team">
                                                            <Skeleton height={20} width={80} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </>
                                ) : (
                                    TrendingplayersList && TrendingplayersList.length > 0 && (
                                        <>
                                            {TrendingplayersList.map((player, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className="topplayerbox" onClick={(e) => handleLink(player.player_player_api_id)} key={index}>
                                                        <div className="topplayerbox-media">
                                                            <img
                                                                src={player.player_logo_url ? player.player_logo_url : constant.DEFAULT_IMAGE}
                                                                alt={player.player_title}
                                                                onError={handleImageError}
                                                            />
                                                        </div>
                                                        <div className="topplayerbox-content">
                                                            <h2 className="player-name">{player.player_title}</h2>
                                                            <div className="player-team"> <img
                                                                src={player.player_team_flag ? player.player_team_flag : constant.DEFAULT_IMAGE}
                                                                alt={player.player_team_name}
                                                                onError={handleImageError}
                                                            /> {player.player_team_name}</div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </>
                                    ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="player-search">
                            <input type="text" placeholder="Thinking of a Player? Search them right away"
                                onChange={(e) => searchPlayers(e.target.value)}
                            ></input>
                            {SearchPlayerList && SearchPlayerList.length > 0 && (
                                <div className="player-search-list" id="search_input">
                                    <ul>
                                        {SearchPlayerList.map((result, index) => (

                                            <li key={index} onClick={(e) => handleLink(result.player_player_api_id)}>{result.player_title}</li> // Adjust based on API response structure
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <span className="sicon"><i className="ri-search-line"></i></span>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
        <section className="section-gap-small">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="player-filter mb-20">
                            <div className="row g-3 align-items-center">
                                <div className="col-lg-3">
                                    <select className="custom-select" onChange={(e) => { setNationality(e.target.value); handleFilterChange(e.target.value, gender, role, isInternational, isActive); }}>
                                        <option value="">Select By Nationality</option>
                                        {TeamList.map((result, index) => (
                                            <option key={index} value={result.team_name_short}>{result.team_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <select className="custom-select" onChange={(e) => { setGender(e.target.value); handleFilterChange(nationality, e.target.value, role, isInternational, isActive); }}>
                                        <option value="">Select By Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="col-lg-2">
                                    <select className="custom-select" onChange={(e) => { setRole(e.target.value); handleFilterChange(nationality, gender, e.target.value, isInternational, isActive); }}>
                                        <option value="">Select By Role</option>
                                        <option value="Batsman">Batsman</option>
                                        <option value="Bowler">Bowler</option>
                                        <option value="Allrounder">All Rounder</option>
                                        <option value="Wicketkeeper">Wicket Keeper</option>
                                    </select>
                                </div>
                                <div className="col-lg-4">
                                    <div className="toogleSec">
                                        <div className="form-check form-switch tooglebox">
                                            <input className="form-check-input" type="checkbox" onChange={(e) => { setIsInternational(e.target.checked); handleFilterChange(nationality, gender, role, e.target.checked, isActive); }} />
                                            <label className="form-check-label" for="flexSwitchCheckDefault">International Only</label>
                                        </div>
                                        <div className="form-check form-switch tooglebox">
                                            <input className="form-check-input" type="checkbox" onChange={(e) => { setIsActive(e.target.checked); handleFilterChange(nationality, gender, role, isInternational, e.target.checked); }} />
                                            <label className="form-check-label" for="flexSwitchCheckDefault">Active Players</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ?
                    <div className="row g-3">
                        {[...Array(12)].map((_, index) => (
                            <div className="col-lg-4" key={index}>
                                <div className="mplayerbox">
                                    <div className="mplayerbox-inner">
                                        <div className="mplayerbox-media">
                                            <Skeleton height={100} />
                                        </div>
                                        <div className="mplayerbox-content">
                                            <h2 className="player-name"><Skeleton height={20} width={150} /></h2>
                                            <ul className="player-meta">
                                                <li><Skeleton height={15} width={60} /></li>
                                                <li className="tx-primary"><Skeleton height={15} width={50} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="mplayerbox-bottom">
                                        <span className="mpodi"><Skeleton height={15} width={60} /></span>
                                        <span className="t20"><Skeleton height={15} width={60} /></span>
                                        <span className="test"><Skeleton height={15} width={60} /></span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> : ''}
                <InfiniteScroll
                    dataLength={playersList.length}
                    next={() => {
                        const nextPage = page + 1;
                        setPage(nextPage); // Update page state
                        fetchPlayersList(nextPage, { nationality, gender, role, isInternational, isActive }); // Fetch data with the next page and filters
                    }}
                    hasMore={hasMore}
                    loader={
                        <div className="row g-3">
                            {[...Array(3)].map((_, index) => (
                                <div className="col-lg-4" key={index}>
                                    <div className="mplayerbox">
                                        <div className="mplayerbox-inner">
                                            <div className="mplayerbox-media">
                                                <Skeleton height={100} />
                                            </div>
                                            <div className="mplayerbox-content">
                                                <h2 className="player-name"><Skeleton height={20} width={150} /></h2>
                                                <ul className="player-meta">
                                                    <li><Skeleton height={15} width={60} /></li>
                                                    <li className="tx-primary"><Skeleton height={15} width={50} /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="mplayerbox-bottom">
                                            <span className="mpodi"><Skeleton height={15} width={60} /></span>
                                            <span className="t20"><Skeleton height={15} width={60} /></span>
                                            <span className="test"><Skeleton height={15} width={60} /></span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    endMessage={<p style={{ textAlign: 'center', marginTop: '10px' }}>No more players to show</p>}
                >
                    <div className="row g-3">
                        {playersList.map((player, index) => (
                            <div className="col-lg-4" onClick={(e) => handleLink(player.player_player_api_id)} key={index}>
                                <div className="mplayerbox">
                                    <div className="mplayerbox-inner">
                                        <div className="mplayerbox-media">
                                            <img
                                                src={player.player_logo_url ? player.player_logo_url : constant.DEFAULT_IMAGE}
                                                alt={player.player_title}
                                                onError={handleImageError}
                                            />
                                        </div>
                                        <div className="mplayerbox-content">
                                            <h2 className="player-name">{player.player_title}</h2>
                                            <ul className="player-meta">
                                                <li>
                                                    <img
                                                        src={player.player_team_flag ? player.player_team_flag : constant.DEFAULT_IMAGE}
                                                        alt={player.player_title}
                                                        onError={handleImageError}
                                                    />
                                                    {player.player_team_name}</li>
                                                <li className="tx-primary">34 Years</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="mplayerbox-bottom">
                                        <span className="mpodi">ODI Rank 5</span>
                                        <span className="t20">T20 Rank 2</span>
                                        <span className="test">Test Rank 10</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
        </section>
        <Footer></Footer>
    </>)
}

export default PlayerList
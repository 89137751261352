import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Component/Services/apiservices";
import Skeleton from 'react-loading-skeleton'; // Import the skeleton loader
import constant from "../../Component/Services/constant";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TrendingPlayer from "../../Component/Elements/trending_player";
import CurrentSeries from "../../Component/Elements/current_series";
import LatestNews from "../../Component/Elements/latest_news";
import { Breadcrumb } from 'react-bootstrap'

const NewsDetail=()=>{
    const { news_id } = useParams();
    const [NewsData, setNewsData] = useState({});
    const [RandomNewsData, setRandomNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [RandomLoading, setRandomLoading] = useState(true);
    const navigate = useNavigate()

    const didMountRef = useRef(true);

    useEffect(() => {
        if (didMountRef.current) {
            getnewsDetail();
            randomNews();
            didMountRef.current = false;
        }
    }, []);

    const getnewsDetail = () => {
        const formData = new FormData();
        formData.append('news_slug', news_id);
        ApiService.postData("newsDetail/", formData)
            .then((res) => {
                if (res.status == 'success') {
                    setNewsData(res.newsDetail);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const randomNews = () => {
        ApiService.fetchData('newsRandom').then((res) => {
            if (res.status === 'success') {
                setRandomNewsData(res.newsData);
                setRandomLoading(false);
            }
        });
    };

    return(<>
    <Header></Header>
        <div className="pageSubheader">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active href="/">{loading ? <Skeleton height={15} width={500} /> : NewsData?.news_title}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
        </div>
        <section className="section-gap-medium">
            <div className="container">
                <div className="row">
                <div className="col-lg-3">
                <div className="section-title">
                    <h6 class="mb-0">Featured news</h6>
                 </div>
                 {RandomLoading ? (
                    <>
                    {[...Array(5)].map((_, index) => (
                <div className="fnewsbox mb-15">
                <div className="fnewsboxMedia">
                    <Skeleton height={150} width={300} />
                </div>
                    <div className="fnewsboxContent">
                        <h2 className="title mb-0"><a href="#"><Skeleton width={270} count={3}></Skeleton></a></h2>
                    </div>
                </div>
                ))}
            </>
            ): (
                RandomNewsData && RandomNewsData.length > 0 && (
                    <>
                {RandomNewsData.map((newslist, index) => (
                <div className="fnewsbox mb-15">
                    <div className="fnewsboxMedia">
                        <a href={`/news-detail/${newslist?.news_slug}`}>
                    <img src={newslist.news_image ? newslist.news_image : constant.DEFAULT_IMAGE}/>
                    </a>
                    </div>
                    <div className="fnewsboxContent">
                        <h2 className="title mb-0"><a href={`/news-detail/${newslist?.news_slug}`}>{newslist?.news_title}</a></h2>
                    </div>
                </div>
                ))}
                </>
                )
            )}
                 
                </div>
                <div className="col-lg-6">
                    <div className="newsdetailsbox">
                        <p className="tx-gray-500">Published: {loading ? <Skeleton width={180} /> : moment(NewsData?.news_publish_date).format('D MMM YYYY, HH:mm [IST]')}</p>
                        <div className="newsdetailsboxMedia">
                            {loading ? (
                                        <Skeleton height={500} width={700} />
                                    ) : (
                                        <img src={NewsData.news_image ? NewsData.news_image : constant.DEFAULT_IMAGE} alt={NewsData?.news_title} />
                                    )}
                            <div className="newsdetailtitle">
                                <h1 className="title mb-0">{loading ? <Skeleton count={2} width={600} /> : NewsData?.news_title}</h1>
                            </div>
                        </div>
                        {loading ? (
                            <div className="newsdetailsboxContent">
                            <Skeleton count={15} />
                           </div> 
                        ) : (
                            <div className="newsdetailsboxContent" dangerouslySetInnerHTML={{ __html: NewsData?.news_content || "-----" }} />
                        )}

                    </div>
                </div>
                <div className="col-lg-3">
                <div className="seriesSidebar">
                    <TrendingPlayer />
                    <CurrentSeries />
                    <LatestNews />
                </div>
                </div>
                </div>
            </div>
        </section>
    <Footer></Footer>
    </>)
}

export default NewsDetail
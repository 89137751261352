import CurrentSeries from "../../Component/Elements/current_series"
import LatestNews from "../../Component/Elements/latest_news"
import Ranking from "../../Component/Elements/ranking"
import TrendingPlayer from "../../Component/Elements/trending_player"
import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { ApiService } from "../../Component/Services/apiservices"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import constant from "../../Component/Services/constant"
import Skeleton from 'react-loading-skeleton';
import { formatMatchWithTime, formatPublishDate, formatPublishTime } from "../../Component/Services/moment"
import MatchLiveLine from "./components/live_line"
import MatchScorecard from "./components/scorecard"
import MatchSquad from "./components/squad"
import MatchCommentry from "./components/commentry"
import MatchPointsTable from "./components/points_table"
import MatchBallByBall from "./components/ball_by_ball"
import MatchInfo from "./components/info"
import MatchGraph from "./components/graph"

const MatchDetail = () => {
    const didMountRef = useRef(true)
    const { match_id } = useParams()
    const [matchDetail, setMatchDetail] = useState({})
    const [loading, setLoading] = useState(true)
    const [matchCardLoading, setMatchCardLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('Info');
    useEffect(() => {
        if (match_id) {
            getMatchDetail()
        }
    }, [match_id])

    const handleToggleTabs = (tab) => {
        setActiveTab(tab);
    };
    const getMatchDetail = () => {
        const dataString = {
            match_id: match_id
        }
        setLoading(true)
        ApiService.postData("match/matchDetail", dataString).then((res) => {
            if (res.status === "success") {
                setMatchDetail(res.matchDetail)
                setLoading(false)
                setTimeout(() => {
                    setMatchCardLoading(false)
                }, 500);
            } else {
                setLoading(false)
                setTimeout(() => {
                    setMatchCardLoading(false)
                }, 500);
            }
        })
    }
    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };
    return (<>
        <Header></Header>
        <div className="pageSubheader">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <Breadcrumb>
                            {loading ?
                                <>
                                    <Breadcrumb.Item href="/"><Skeleton width={40} height={15} /></Breadcrumb.Item>
                                    <Breadcrumb.Item href="/"><Skeleton width={350} height={15} /></Breadcrumb.Item>
                                    <Breadcrumb.Item href="/"><Skeleton width={100} height={15} /></Breadcrumb.Item>
                                </>
                                :
                                <>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href={'/match-detail/' + matchDetail && matchDetail.match_id > 0 ? '' : ''}>{matchDetail && matchDetail.match_title ? matchDetail.match_title : ''}</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="/">{activeTab}</Breadcrumb.Item>
                                </>
                            }
                        </Breadcrumb>
                    </div>
                </div>
            </div>
        </div>
        <section className="section-gap-small pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        {loading ?
                            <div className="matchDbox">
                                <h1 className="match-name"><Skeleton width={700} height={25} /></h1>
                                <p className="matchDbox-subtitle"><Skeleton width={600} height={15} /></p>
                            </div>
                            :
                            <div className="matchDbox">
                                <h1 className="match-name">{matchDetail.match_subtitle}. {matchDetail.match_series}</h1>
                                <p className="matchDbox-subtitle">{formatMatchWithTime(matchDetail.match_start_date)} | {matchDetail.match_venue_name}</p>
                            </div>
                        }
                        {matchDetail.match_status == 1 ?
                            // Upcoming
                            matchCardLoading ?
                                <div className="matchdetails-section mb-20">
                                    <div className="upcoming-match">
                                        <div className="um-team">
                                            <div className="um-team-img"><Skeleton width={80} height={70} /></div>
                                            <div className="um-team-content">
                                                <h6 className="mb-0"><Skeleton width={80} height={15} /></h6>
                                            </div>
                                        </div>
                                        <div className="um-match-start text-center">
                                            <p className="tx-gray-500 mb-1"><Skeleton width={80} height={15} /></p>
                                            <h3 className="mb-o"><Skeleton width={150} height={25} /></h3>
                                        </div>
                                        <div className="um-team">
                                            <div className="um-team-img"><Skeleton width={80} height={70} /></div>
                                            <div className="um-team-content">
                                                <h6 className="mb-0"><Skeleton width={80} height={15} /></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="matchdetails-section mb-20">
                                    <div className="upcoming-match">
                                        <div className="um-team">
                                            <div className="um-team-img"><img src={matchDetail.match_teama_image} alt={matchDetail.match_teama_name} onError={handleImageError}></img></div>
                                            <div className="um-team-content">
                                                <h6 className="mb-0">{matchDetail.match_teama_name}</h6>
                                            </div>
                                        </div>
                                        <div className="um-match-start text-center">
                                            <p className="tx-gray-500 mb-1">{formatPublishDate(matchDetail.match_start_date)}</p>
                                            <h3 className="mb-o">{matchDetail.match_match_time}</h3>
                                        </div>
                                        <div className="um-team">
                                            <div className="um-team-img"><img src={matchDetail.match_teamb_image} alt={matchDetail.match_teamb_name} onError={handleImageError}></img></div>
                                            <div className="um-team-content">
                                                <h6 className="mb-0">{matchDetail.match_teamb_name}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : matchDetail.match_status == 2 ?
                                // Finshed
                                matchCardLoading ?
                                    <div className="matchdetails-section mb-20">
                                        <div className="liveandfinish">
                                            <div className="liveandfinish-header d-flex align-items-center">
                                                <p className="tx-gray-500 mb-0"><Skeleton width={500} height={15} /></p>
                                            </div>
                                            <div className="liveandfinish-middle">
                                                <div className="liveandfinish-middle-left">
                                                    <div className="livematchteam">
                                                        <div className="lteamdetails">
                                                            <figure className="lteamdetailsMedia"><Skeleton width={50} height={50} /></figure>
                                                            <h6 className="mb-0 lteamdetailsname"><Skeleton width={50} height={15} /></h6>
                                                        </div>
                                                        <div className="lscoredetilas">
                                                            <span className="score"><Skeleton width={50} height={15} /></span>
                                                            <span className="over"><Skeleton width={50} height={15} /></span>
                                                        </div>
                                                    </div>
                                                    <div className="livematchteam">
                                                        <div className="lteamdetails">
                                                            <figure className="lteamdetailsMedia"><Skeleton width={50} height={50} /></figure>
                                                            <h6 className="mb-0 lteamdetailsname"><Skeleton width={50} height={15} /></h6>
                                                        </div>
                                                        <div className="lscoredetilas">
                                                            <span className="score"><Skeleton width={50} height={15} /></span>
                                                            <span className="over"><Skeleton width={50} height={15} /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="liveandfinish-middle-right">
                                                    <div className="liveResult">
                                                        <p className="mb-0 text-success"><Skeleton width={200} height={25} /></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="liveandfinish-footer">

                                            </div>
                                        </div>
                                    </div>
                                    : <div className="matchdetails-section mb-20">
                                        <div className="liveandfinish">
                                            <div className="liveandfinish-header d-flex align-items-center">
                                                <p className="tx-gray-500 mb-0">{matchDetail.match_subtitle} | {matchDetail.match_series}</p>
                                            </div>
                                            <div className="liveandfinish-middle">
                                                <div className="liveandfinish-middle-left">
                                                    <div className="livematchteam">
                                                        <div className="lteamdetails">
                                                            <figure className="lteamdetailsMedia"><img src={matchDetail.match_teama_image} alt={matchDetail.match_teama_name} onError={handleImageError} /></figure>
                                                            <img src={matchDetail.match_teama_image} className="lteamdetailsimg" alt={matchDetail.match_teama_name} onError={handleImageError}></img>
                                                            <h6 className="mb-0 lteamdetailsname">{matchDetail.match_teama_shortname}</h6>
                                                        </div>
                                                        <div className="lscoredetilas">
                                                            {matchDetail.match_team_a_scores && (<span className="score">{matchDetail.match_team_a_scores}</span>)}
                                                            {matchDetail.match_team_a_over && (<span className="over">({matchDetail.match_team_a_over} Ov)</span>)}
                                                        </div>
                                                    </div>
                                                    <div className="livematchteam">
                                                        <div className="lteamdetails">
                                                            <figure className="lteamdetailsMedia"><img src={matchDetail.match_teamb_image} alt={matchDetail.match_teamb_name} onError={handleImageError} /></figure>
                                                            <img src={matchDetail.match_teamb_image} className="lteamdetailsimg" alt={matchDetail.match_teamb_name} onError={handleImageError}></img>
                                                            <h6 className="mb-0 lteamdetailsname">{matchDetail.match_teamb_shortname}</h6>
                                                        </div>
                                                        <div className="lscoredetilas">
                                                            {matchDetail.match_team_b_scores && (<span className="score">{matchDetail.match_team_b_scores}</span>)}
                                                            {matchDetail.match_team_b_over && (<span className="over">({matchDetail.match_team_b_over} Ov)</span>)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="liveandfinish-middle-right">
                                                    <div className="liveResult">
                                                        <p className="mb-0 text-success">{matchDetail.match_result}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                : matchDetail.match_status == 3 ?
                                    // Live
                                    <div className="matchdetails-section mb-20">
                                        <div className="liveandfinish">
                                            <div className="liveandfinish-header d-flex align-items-center">
                                                <p className="tx-gray-500 mb-0">{matchDetail.match_subtitle} | {matchDetail.match_series}</p>
                                            </div>
                                            <div className="liveandfinish-middle">
                                                <div className="liveandfinish-middle-left">
                                                    <div className="livematchteam">
                                                        <div className="lteamdetails">
                                                            <figure className="lteamdetailsMedia"><img src={matchDetail.match_teama_image} alt={matchDetail.match_teama_name} onError={handleImageError} /></figure>
                                                            <img src={matchDetail.match_teama_image} alt={matchDetail.match_teama_name} onError={handleImageError} className="lteamdetailsimg" ></img>
                                                            <h6 className="mb-0 lteamdetailsname">{matchDetail.match_teama_shortname}</h6>
                                                        </div>
                                                        <div className="lscoredetilas">
                                                            <span className="score">372-10 &amp; 124-10</span>
                                                            <span className="over">(108.2 &amp; 31.0 Ov)</span>
                                                        </div>
                                                    </div>
                                                    <div className="livematchteam">
                                                        <div className="lteamdetails">
                                                            <figure className="lteamdetailsMedia"><img src={matchDetail.match_teamb_image} alt={matchDetail.match_teamb_name} onError={handleImageError} /></figure>
                                                            <img src={matchDetail.match_teamb_image} alt={matchDetail.match_teamb_name} onError={handleImageError} className="lteamdetailsimg"></img>
                                                            <h6 className="mb-0 lteamdetailsname">{matchDetail.match_teamb_shortname}</h6>
                                                        </div>
                                                        <div className="lscoredetilas">
                                                            <span className="score">372-10 &amp; 124-10</span>
                                                            <span className="over">(108.2 &amp; 31.0 Ov)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="liveandfinish-middle-right">
                                                    <div className="liveScoreResult">
                                                        <span className="text-danger score">4</span>
                                                    </div>
                                                    <p className="mb-0"></p>
                                                </div>
                                            </div>
                                            <div className="liveandfinish-footer">
                                                <div className="currentrunrate">
                                                    Current Run Rate: 4.76  |  Required Run Rate: 8.57
                                                </div>
                                                <div className="current-over">
                                                    <ul>
                                                        <li>Over 17</li>
                                                        <li className="over">1</li>
                                                        <li className="over">1</li>
                                                        <li className="over">1</li>
                                                        <li className="over">1</li>
                                                        <li className="over">1</li>
                                                        <li>= 10 Runs</li>
                                                        <li>|</li>
                                                        <li>Over 17</li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                        <div className="lmatchTabs mb-20">
                            <ul>
                                <li className={activeTab === 'Info' ? 'active' : ''} onClick={() => handleToggleTabs('Info')} >Info</li>
                                <li className={activeTab === 'Live Line' ? 'active' : ''} onClick={() => handleToggleTabs('Live Line')} >Live Line</li>
                                <li className={activeTab === 'Scorecard' ? 'active' : ''} onClick={() => handleToggleTabs('Scorecard')} >Scorecard</li>
                                <li className={activeTab === 'Squad' ? 'active' : ''} onClick={() => handleToggleTabs('Squad')} >Squad</li>
                                <li className={activeTab === 'Commentry' ? 'active' : ''} onClick={() => handleToggleTabs('Commentry')} >Commentry</li>
                                <li className={activeTab === 'Points Table' ? 'active' : ''} onClick={() => handleToggleTabs('Points Table')} >Points Table</li>
                                <li className={activeTab === 'Ball By Ball' ? 'active' : ''} onClick={() => handleToggleTabs('Ball By Ball')} >Ball By Ball</li>
                                <li className={activeTab === 'Graph' ? 'active' : ''} onClick={() => handleToggleTabs('Graph')} >Graph</li>
                            </ul>
                        </div>
                        {activeTab === 'Info' ?
                            <MatchInfo matchDetail={matchDetail}></MatchInfo>
                            :
                            activeTab === 'Live Line' ?
                                <MatchLiveLine></MatchLiveLine>
                                :
                                activeTab === 'Scorecard' ?
                                    <MatchScorecard matchid={matchDetail.match_id}></MatchScorecard>
                                    :
                                    activeTab === 'Squad' ?
                                        <MatchSquad></MatchSquad>
                                        :
                                        activeTab === 'Commentry' ?
                                            <MatchCommentry></MatchCommentry>
                                            :
                                            activeTab === 'Points Table' ?
                                                <MatchPointsTable></MatchPointsTable>
                                                :
                                                activeTab === 'Ball By Ball' ?
                                                    <MatchBallByBall></MatchBallByBall>
                                                    :
                                                    activeTab === 'Graph' ?
                                                        <MatchGraph></MatchGraph>
                                                        : null}
                    </div>
                    <div className="col-lg-3">
                        <div className="seriesSidebar">
                            <TrendingPlayer />
                            <CurrentSeries />
                            <Ranking />
                            <LatestNews />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </>)
}

export default MatchDetail
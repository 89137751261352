// const API_URL = "https://heroliveline.com/csadmin/api/"
// const API_URL = "https://www.codexo.store/heroliveline/api/web-api/"
const API_URL = "https://app.heroliveline.com/csadmin/api/web/"
const LIVE_API_URL = "https://app.heroliveline.com/csadmin/api/cron/"



const BASE_URL = ""
const FRONT_URL = "https://heroliveline.com/"
const CRICKET_API_URL = "https://apicricketchampion.in/apiv4/"
const CRICKET_API_TOKEN = "deed03c60ab1c13b1dbef6453421ead6"
const DEFAULT_IMAGE = "/img/defaultimage.png";
const CATEGORIES = [];
const themesetting = {};
export default { API_URL, CATEGORIES, themesetting, BASE_URL, FRONT_URL, DEFAULT_IMAGE, CRICKET_API_URL, CRICKET_API_TOKEN, LIVE_API_URL };
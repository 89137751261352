import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatPublishDateWithTime } from '../Services/moment';

function MatchCard({ matchlist, className }) {
    const navigate = useNavigate()

    const handleLink = (matchId) => {
        navigate(`/match-detail/${matchId}`);
    };
    const getMatchStatus = (status) => {
        switch (status) {
            case 1:
                return <span className="Upcoming">Upcoming</span>;
            case 2:
                return <span className="finished">Finished</span>;
            case 3:
                return <span className="live">Live</span>;
            default:
                return null; // Return null instead of an empty string to avoid rendering an empty span
        }
    };

    const convertToFractional = (decimalOdds) => {
        if (decimalOdds > 0) {
            const fractionalOdds = decimalOdds - 1;
            const numerator = Math.round(fractionalOdds * 100);
            if (numerator < 0) {
                return '0';
            } else {
                return `${numerator}`;
            }
        } else {
            return '0';
        }
    };

    return (
        <>
            <div className={"matchCard " + className} onClick={() => handleLink(matchlist.match_id)}>
                <div className="matchCardHeader">
                    <div className="left">
                        <span className="seriesname">{matchlist.match_subtitle}, {matchlist.match_series}</span>
                    </div>
                    <div className="right justify-content-end">
                        {getMatchStatus(matchlist.match_status)}
                    </div>
                </div>
                <div className="matchCardMiddle">
                    <div className="matchCardMiddleLeft">
                        <div className="matchTeam">
                            <figure className="matchTeamMedia">
                                <img src={matchlist.match_teama_image} alt={matchlist.match_teama_shortname}></img>
                            </figure>
                            <img src={matchlist.match_teama_image} className="bimg" alt={matchlist.match_teama_shortname}></img>
                            <h6 className="mb-0 matchTeamname">{matchlist.match_teama_shortname}</h6>
                        </div>
                        <div className="matchTeam">
                            <figure className="matchTeamMedia">
                                <img src={matchlist.match_teamb_image} alt={matchlist.match_teamb_shortname}></img>
                            </figure>
                            <img src={matchlist.match_teamb_image} className="bimg" alt={matchlist.match_teamb_shortname}></img>
                            <h6 className="mb-0 matchTeamname">{matchlist.match_teamb_shortname}</h6>
                        </div>
                    </div>
                    {matchlist.match_status == 3 ?
                        matchlist.match_team_a_scores || matchlist.match_team_a_over || matchlist.match_team_b_scores || matchlist.match_team_b_over ?
                            <div className="matchCardMiddleRight">
                                {matchlist.match_team_a_scores && matchlist.match_team_a_over ?
                                    <div className="matchTeamScore">
                                        {matchlist.match_team_a_scores && (<span className="score">{matchlist.match_team_a_scores}</span>)}
                                        {matchlist.match_team_a_over && (<span className="over">({matchlist.match_team_a_over} Ov)</span>)}
                                    </div>
                                    :
                                    <div className="matchTeamScore">
                                        <span className="score">Yet to Bat</span>
                                    </div>
                                }
                                {matchlist.match_team_b_scores && matchlist.match_team_b_over ?
                                    <div className="matchTeamScore">
                                        {matchlist.match_team_b_scores && (<span className="score">{matchlist.match_team_b_scores}</span>)}
                                        {matchlist.match_team_b_over && (<span className="over">({matchlist.match_team_b_over} Ov)</span>)}
                                    </div>
                                    :
                                    <div className="matchTeamScore">
                                        <span className="score">Yet to Bat</span>
                                    </div>
                                }
                            </div>
                            :
                            matchlist.match_need_run_ball ?
                                <div className="matchCardMiddleRight">
                                    <div className="matchCardMiddleRight-inner">
                                        <div className="matchStatus">
                                            <span className='text-success'>{matchlist.match_need_run_ball}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="matchCardMiddleRight">
                                    <div className="matchCardMiddleRight-inner">
                                        <div className="matchStatus">
                                            <span className='text-success'>{matchlist.match_venue_name}</span>
                                        </div>
                                    </div>
                                </div>
                        :
                        matchlist.match_status == 2 ?
                            <div className="matchCardMiddleRight">
                                <div className="matchCardMiddleRight-inner">
                                    <div className="matchStatus">
                                        <span className='text-success'>{matchlist.match_result}</span>
                                    </div>
                                </div>
                            </div>
                        :
                        <div className="matchCardMiddleRight">
                            <div className="matchCardMiddleRight-inner">
                                <div className="matchStatus">
                                    <span className='text-success'><p className='mb-0'>{matchlist.match_venue_name}</p><p className='mt-0'>{formatPublishDateWithTime(matchlist.match_date_start_ist)}</p></span>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="matchCardScore">
                    </div>
                </div>
                <div className="matchCardFooter">
                    <div className="left">
                        <ul>
                            <li>Fantasy <i className="ri-arrow-right-s-line"></i></li>
                            <li>Point Table <i className="ri-arrow-right-s-line"></i></li>
                        </ul>
                    </div>
                    {matchlist.match_status == 3 ?
                        <div className="right">
                            {matchlist.match_fav_team && (<span className="name">{matchlist.match_fav_team}</span>)}
                            {Number(matchlist.match_min_rate) > 0 ? <span className="back">{convertToFractional(matchlist.match_min_rate)}</span> : <span className="back">-</span>}
                            {Number(matchlist.match_max_rate) > 0 ? <span className="lay">{convertToFractional(matchlist.match_max_rate)}</span> : <span className="lay">-</span>}
                        </div>
                        :
                        matchlist.match_status == 2 ?
                            <div className="right">
                                {matchlist.match_fav_team && (<span className="name">{matchlist.match_fav_team}</span>)}
                                {Number(matchlist.match_min_rate) > 0 ? <span className="back">{convertToFractional(matchlist.match_min_rate)}</span> : <span className="back">-</span>}
                                {Number(matchlist.match_max_rate) > 0 ? <span className="lay">{convertToFractional(matchlist.match_max_rate)}</span> : <span className="lay">-</span>}
                            </div>
                            : matchlist.match_status == 1 ?
                                <div className="right">
                                    {matchlist.match_fav_team && (<span className="name">{matchlist.match_fav_team}</span>)}
                                    {Number(matchlist.match_min_rate) > 0 ? <span className="back">{convertToFractional(matchlist.match_min_rate)}</span> : <span className="back">-</span>}
                                    {Number(matchlist.match_max_rate) > 0 ? <span className="lay">{convertToFractional(matchlist.match_max_rate)}</span> : <span className="lay">-</span>}
                                </div>
                                : null}
                </div>
            </div>
        </>
    );
}

export default MatchCard;

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ApiService } from "../../../Component/Services/apiservices"
import Skeleton from "react-loading-skeleton";
import constant from "../../../Component/Services/constant";
import moment from 'moment';
const SeriesOverview = ({ seriesDetail }) => {
    const didMountRef = useRef(true)
    const [matchesList, setMatchesList] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getFinishedMatchesData()
        }
        didMountRef.current = false
    }, [])

    const getFinishedMatchesData = () => {
        const dataString = {
            series_cid: seriesDetail.series_cid
        }
        ApiService.postData("series/seriesFinishedMatches", dataString).then((res) => {
            if (res.status === "success") {
                setMatchesList(res.resData)
            }
            setLoading(false)
        })
    }

    const handleLink = (matchId) => {
        navigate(`/match-detail/${matchId}`);
    };
    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };
    const formatPublishDate = (date) => {
        const format = 'DD MMM YYYY, dddd';
        const formattedDate = moment(date, format).format('DD MMM YYYY');
        return formattedDate;
    }
    return (
        <>
            {loading ? (
                <>
                    <div className="section-title">
                        <h5 className="mb-0"><Skeleton width={150} height={20} /></h5>
                    </div>
                    <div className="cardBox mb-30">
                        <div className="cardBoxBody">
                            {[...Array(5)].map((_, index) => (
                                <div className="hmatchbox" key={index}>
                                    <div className="hmatchbox-left">
                                        <div className="hmtmatch">
                                            <Skeleton circle width={40} height={40} />
                                            <div className="hmtmatchContent">
                                                <h6 className="hmtmatch-name"><Skeleton width={30} height={15} /></h6>
                                            </div>
                                        </div>
                                        <div className="hmatchbox-center">
                                            <p className="mb-0 tx-gray-500"><Skeleton width={50} height={15} /></p>
                                        </div>
                                        <div className="hmatchbox-right justify-content-end">
                                            <div className="hmtmatch">
                                                <div className="hmtmatchContent tx-right">
                                                    <h6 className="hmtmatch-name"><Skeleton width={30} height={15} /></h6>
                                                </div>
                                                <Skeleton circle width={40} height={40} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                matchesList && matchesList.length > 0 && (
                    <>
                        <div className="section-title">
                            <h5 className="mb-0">Features Matches</h5>
                        </div>
                        <div className="cardBox mb-30">
                            <div className="cardBoxBody">
                                {matchesList.map((match, index) => (
                                    <div className="hmatchbox" onClick={() => handleLink(match.match_id)} key={index}>
                                        <div className="hmatchbox-left">
                                            <div className="hmtmatch">
                                                <div className="hmtmatchMedia">
                                                    <img src={match.match_teama_image} alt={match.match_teama_shortname} onError={handleImageError}></img>
                                                </div>
                                                <img src={match.match_teama_image} className="bimg" alt={match.match_teama_shortname} onError={handleImageError}></img>
                                                <div className="hmtmatchContent">
                                                    <h6 className="hmtmatch-name">{match.match_teama_shortname}</h6>
                                                    <div className="hmtmatch-score">
                                                        <span className="score">{match.match_team_a_scores}</span>
                                                        <span className="over">({match.match_team_a_over} ov)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hmatchbox-center">
                                            <p className="mb-0 tx-gray-500">{formatPublishDate(match.match_date_wise)}</p>
                                        </div>
                                        <div className="hmatchbox-right justify-content-end">
                                            <div className="hmtmatch">
                                                <img src={match.match_teamb_image} className="cimg" alt={match.match_teamb_shortname} onError={handleImageError}></img>
                                                <div className="hmtmatchContent tx-right">
                                                    <h6 className="hmtmatch-name">{match.match_teamb_shortname}</h6>
                                                    <div className="hmtmatch-score">
                                                        <span className="score">{match.match_team_b_scores}</span>
                                                        <span className="over">({match.match_team_b_over} ov)</span>
                                                    </div>
                                                </div>
                                                <div className="hmtmatchMedia">
                                                    <img src={match.match_teamb_image} alt={match.match_teamb_shortname} onError={handleImageError}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )
            )}
        </>
    )
}

export default SeriesOverview
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../Services/apiservices";
import constant from "../Services/constant";
import Skeleton from 'react-loading-skeleton'
import { formatPublishDate, formatTimeFromNow } from "../Services/moment";
import { useNavigate } from "react-router-dom";
const LatestNews = () => {
    const didMountRef = useRef(true);
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            latestNews();
            didMountRef.current = false;
        }
    }, []);

    const latestNews = () => {
        ApiService.fetchData('latestNews').then((res) => {
            if (res.status === 'success') {
                setNewsData(res.newsData);
                setLoading(false);
            }
        });
    };
    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };
    const handleLink = (playerId) => {
        window.location.href = `/news-detail/${playerId}`;
    };
    return (
        <>
            {loading ? (
                <>
                    <div className="section-title">
                        <h6 className="mb-0"><Skeleton width={140} height={20} /></h6>
                        <a href="javascript:void(0)" className="viewallbutton"><Skeleton height={20} width={80} /></a>
                    </div>
                    <div className="cardBox mb-30">
                        {[...Array(5)].map((_, index) => (
                            <div className="newsBox" key={index}>
                                <div className="newsBoxMedia"><Skeleton width={120} height={80} /></div>
                                <div className="newsBoxContent">
                                    <h2 className="title"><Skeleton width={120} height={20} /></h2>
                                    <ul className="newBoxMeta">
                                        <li><Skeleton width={100} height={10} /></li>
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                newsData && newsData.length > 0 && (
                    <>
                        <div className="section-title">
                            <h6 className="mb-0">Latest News</h6>
                            <a href="/news" className="viewallbutton">View All</a>
                        </div>
                        <div className="cardBox mb-30">
                            {newsData.slice(0,5).map((newslist, index) => (
                                <div className="newsBox" onClick={(e) => handleLink(newslist.news_slug)} key={index}> 
                                    <div className="newsBoxMedia">
                                        <img src={newslist.news_image ? newslist.news_image : constant.DEFAULT_IMAGE} alt="News image"
                                            onError={handleImageError}
                                        />
                                    </div>
                                    <div className="newsBoxContent">
                                        <h2 className="title">{newslist.news_title}</h2>
                                        <ul className="newBoxMeta">
                                            <li><i className="ri-calendar-line"></i> {formatPublishDate(newslist.news_publish_date)}  |  {formatTimeFromNow(newslist.news_publish_date)}</li>
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )
            )}
        </>


    )
}

export default LatestNews
const MatchCommentry=()=>{
    return(
        <div>
            <div className="section-title">
                <h5 className="mb-0">Commentry</h5>
            </div>
            <div className="cardBox mb-30">
                <div className="comentrybox">
                    <div className="comentrybox-header">
                        <div className="comentryTabs">
                            <ul>
                                <li>All</li>
                                <li>Over</li>
                                <li>W</li>
                                <li>4</li>
                                <li>6</li>
                                <li>1 Innings</li>
                                <li>2 Innings</li>
                            </ul>
                        </div>
                    </div>
                    <div className="comentrybox-header-bottom">
                        <ul className="chblist">
                            <li className="text-center">
                                <h6 className="fw700 mb-1">19</h6>
                                <p className="mb-0 tx-primary">End of over:</p>
                            </li>
                            <li className="text-center">
                                <h6 className="fw700 mb-1">15</h6>
                                <p className="mb-0 tx-primary">Runs</p>
                            </li>
                            <li className="text-center">
                            <h6 className="fw700 mb-1">SRH <span className="tx-primary">180/2</span></h6>
                                <p className="mb-0 tx-primary">Score after 19 overs</p>
                            </li>
                            <li className="text-center">
                               <p className="mb-0">N Pooran <span className="tx-primary">23(12)</span></p>
                               <p className="mb-0">N Pooran <span className="tx-primary">23(12)</span></p>
                               
                            </li>
                            <li className="text-center">
                                <h6 className="fw700 mb-1">4 - 0 - 4 - 0 - 6 - 1</h6>
                                <p className="mb-0 tx-primary">Tu Deshpande</p>
                            </li>
                        </ul>
                    </div>
                    <div className="p-15">
                        <div className="comentry">
                            <div className="comentryovrun">
                                <span className="tx-13 tx-gray-500">18.6</span>
                                <span className="ov">4</span>
                            </div>
                            <div className="comentrytext">
                                <h6 className="fw700"><span className="tx-primary">4 Runs,</span> Deshpande to Pooran</h6>
                                <p className="mb-0 tx-gray-500">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cardBox mb-15">
                <div className="cmoverbox">
                    <div className="cmoverbox-header">
                        <div className="cmoverbox-overbox">
                            <h6>19TH OVER</h6>
                            <p className="mb-0 tx-primary">15 Runs</p>
                        </div>
                        <div className="cmoverbox-ovdetails">
                            <p>Tu Deshpande to Pooran & Stoinis</p>
                            <ul className="ovlisy">
                                <li>
                                    <div className="ovlisy-over ov6">6</div>
                                    <span className="tx-12 tx-gray-500">18.6</span>
                                </li>
                                <li>
                                    <div className="ovlisy-over ov4">4</div>
                                    <span className="tx-12 tx-gray-500">18.6</span>
                                </li>
                                <li>
                                    <div className="ovlisy-over ovwd">WD</div>
                                    <span className="tx-12 tx-gray-500">18.6</span>
                                </li>
                                <li>
                                    <div className="ovlisy-over ovrun">2</div>
                                    <span className="tx-12 tx-gray-500">18.6</span>
                                </li>
                                <li>
                                    <div className="ovlisy-over ovn">N</div>
                                    <span className="tx-12 tx-gray-500">18.6</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="cmoverbox-bottom">
                    <ul className="chblist">
                            
                            <li className="text-center">
                            <h6 className="fw700 mb-1">SRH <span className="tx-primary">180/2</span></h6>
                                <p className="mb-0 tx-primary">Score after 19 overs</p>
                            </li>
                            <li className="text-center">
                               <p className="mb-0">N Pooran <span className="tx-primary">23(12)</span></p>
                               <p className="mb-0">N Pooran <span className="tx-primary">23(12)</span></p>
                               
                            </li>
                            <li className="text-center">
                                <h6 className="fw700 mb-1">4 - 0 - 4 - 0 - 6 - 1</h6>
                                <p className="mb-0 tx-primary">Tu Deshpande</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        )
}

export default MatchCommentry
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ApiService } from '../Services/apiservices';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import MatchCard from './match_card';
import { useLocation } from 'react-router-dom';
function HomeMatchSection() {
    const location = useLocation();
    const didMountRef = useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [filterIsLoading, setFilterIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('All');
    const [liveMatchsIds, setLiveMatchIds] = useState([]);
    const [liveMatchsCount, setLiveMatchCount] = useState(0);
    const [filteredMatches, setFilteredMatches] = useState([]);
    let intervalRef = useRef(null);
    const navigate = useNavigate()
    const handleToggle = (tab) => {
        setActiveTab(tab);
        filterMatches(tab);
    };

    useEffect(() => {
        if (didMountRef.current) {
            getMatchList(activeTab);
            
        }
        didMountRef.current = false;
    }, [location.pathname, activeTab, filteredMatches]);

    useEffect(() => {
        return () => {
            clearExistingInterval();
        };
    }, [location.pathname]);

    const getMatchList = (tab) => {
        setIsLoading(true);
        const dataString = {
            match_status: tab
        }
        ApiService.postData("getmatchlisting", dataString).then((res) => {
            if (res.status === "success") {
                setFilteredMatches(res.matchData);
                if (res && res.matchData) {
                    setLiveMatchCount(res.liveCount)
                    const matchIds = res.matchData
                        .filter((item) => Number(item.match_status) === 3)
                        .map((item) => item.match_api_id);
                    setLiveMatchIds(matchIds, res.matchData);
                    if (matchIds.length > 0) {
                        startInterval(matchIds, res.matchData)
                    } else {
                        clearExistingInterval()
                    }
                }
            }
            setIsLoading(false);
            setFilterIsLoading(false);
        });
    };

    const filterMatches = (tab) => {
        const dataString = {
            match_status: tab
        }
        setFilterIsLoading(true);
        clearExistingInterval()
        ApiService.postData("getmatchlisting", dataString).then((res) => {
            if (res.status === "success") {
                setFilteredMatches(res.matchData);
                if (res && res.matchData) {
                    setLiveMatchCount(res.liveCount)
                    const matchIds = res.matchData
                        .filter((item) => Number(item.match_status) === 3)
                        .map((item) => item.match_api_id);
                    setLiveMatchIds(matchIds, res.matchData);
                    if (matchIds.length > 0) {
                        startInterval(matchIds, res.matchData)
                    }
                }
            }
            setFilterIsLoading(false);
        });
    };

    const startInterval = (matchIds, matchData) => {
        intervalRef.current = setInterval(() => {
            if (location.pathname === '/') {
                liveMatchesApi(matchIds, matchData);
                //liveCricMatchesApi(matchData)
            }
        }, 1000);
    };

    const clearExistingInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    const liveMatchesApi = (matchIds, matchData) => {
        const dataString = {
            match_ids: matchIds
        }
        ApiService.postCronData("matchLiveBulkInfo", dataString).then((res) => {
            if (res.bulk_matches) {
                if (res.bulk_matches.live.length > 0) {
                    const liveMatchesMap = new Map(res.bulk_matches.live.map(item => [item.match_id, item]));
                    const updatedMatches = matchData.map((item2) => {
                        const item1 = liveMatchesMap.get(item2.match_api_id);
                        if (item1) {
                            if (item1.match_type === 'Test') {
                                item2.match_fav_team = item1.fav_team;
                                item2.match_min_rate = item1[`min_rate`];
                                item2.match_max_rate = item1[`max_rate`];
                                for (let index = 3; index > 0; index--) {
                                    if(Number(item1[`min_rate_${index}`]) >= 0){
                                        item2.match_min_rate = item1[`min_rate_${index}`];
                                        item2.match_max_rate = item1[`max_rate_${index}`];
                                        break; 
                                    }
                                }  
                            } else {
                                item2.match_fav_team = item1.fav_team;
                                item2.match_min_rate = item1.min_rate;
                                item2.match_max_rate = item1.max_rate;
                            }

                            if (Number(item1.current_inning) == 2) {
                                if (item1.result) {
                                    item2.match_toss = item1.result;
                                    item2.match_need_run_ball = item1.result;
                                    item2.match_venue_name = item1.result;
                                } else if (item1.need_run_ball) {
                                    item2.match_toss = item1.need_run_ball;
                                    item2.match_need_run_ball = item1.need_run_ball;
                                    item2.match_venue_name = item1.need_run_ball;
                                } else {
                                    item2.match_toss = item1.first_circle;
                                    item2.match_toss = item1.first_circle;
                                    item2.match_venue_name = item1.first_circle;
                                }
                            } else if (Number(item1.current_inning) == 1) {
                                item2.match_toss = item1.toss != '' ? item1.toss : item1.first_circle;
                                item2.match_need_run_ball = item1.toss != '' ? item1.toss : item1.first_circle;
                                item2.match_venue_name = item1.toss != '' ? item1.toss : item1.first_circle;
                            }

                            if (Number(item1.batting_team) === Number(item2.match_teama_id)) {
                                item2.match_team_a_scores = item1.team_a_scores;
                                item2.match_team_a_over = item1.team_a_over;
                                if (Number(item1.team_b_over) > 0) {
                                    item2.match_team_b_scores = item1.team_b_scores;
                                    item2.match_team_b_over = item1.team_b_over;
                                } else {
                                    item2.match_team_b_scores = '';
                                    item2.match_team_b_over = '';
                                }
                            } else if (Number(item1.batting_team) === Number(item2.match_teamb_id)) {
                                item2.match_team_b_scores = item1.team_b_scores;
                                item2.match_team_b_over = item1.team_b_over;
                                if (Number(item1.team_a_over) > 0) {
                                    item2.match_team_a_scores = item1.team_a_scores;
                                    item2.match_team_a_over = item1.team_a_over;
                                } else {
                                    item2.match_team_a_scores = '';
                                    item2.match_team_a_over = '';
                                }
                            }
                        }

                        return item2;
                    });
                    setFilteredMatches(updatedMatches);
                }
            }
        });
    };

    const liveCricMatchesApi = (matchData) => {
        ApiService.fetchCricketData("liveMatchList/").then((res) => {
            if (res.status) {
                if (res.data.length > 0) {
                    const liveMatchesMap = new Map(res.data.map(item => [item.match_id, item]));

                    const updatedMatches = matchData.map((item2) => {
                        const item1 = liveMatchesMap.get(item2.match_api_id);
                        if (item1) {
                            item2.match_fav_team = item1.fav_team;
                            item2.match_min_rate = item1.min_rate;
                            item2.match_max_rate = item1.max_rate;

                            if (Number(item1.batting_team) === Number(item2.match_teama_id)) {
                                item2.match_team_a_scores = item1.team_a_scores;
                                item2.match_team_a_over = item1.team_a_over;
                                if (Number(item1.team_b_over) > 0) {
                                    item2.match_team_b_scores = item1.team_b_scores;
                                    item2.match_team_b_over = item1.team_b_over;
                                } else {
                                    item2.match_team_b_scores = '';
                                    item2.match_team_b_over = '';
                                }
                            } else if (Number(item1.batting_team) === Number(item2.match_teamb_id)) {
                                item2.match_team_b_scores = item1.team_b_scores;
                                item2.match_team_b_over = item1.team_b_over;
                                if (Number(item1.team_a_over) > 0) {
                                    item2.match_team_a_scores = item1.team_a_scores;
                                    item2.match_team_a_over = item1.team_a_over;
                                } else {
                                    item2.match_team_a_scores = '';
                                    item2.match_team_a_over = '';
                                }
                            }
                        }
                        return item2;
                    });
                    setFilteredMatches(updatedMatches);
                }
            }
        });
    };

    return (
        <>
            <section className="section-gap-small match-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {isLoading ? (
                                <>
                                    <div className="section-title">
                                        <Skeleton width={180} height={30} />
                                    </div>
                                    <ul className="customTabs">
                                        {Array.from({ length: 4 }).map((_, index) => (
                                            <li key={index}>
                                                <Skeleton width={50} height={20} />
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="matchSlider">
                                        <Swiper spaceBetween={15}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 1.2,
                                                },
                                                768: {
                                                    slidesPerView: 1.2,
                                                },
                                                992: {
                                                    slidesPerView: 3,
                                                },
                                            }}
                                        >
                                            {Array.from({ length: 5 }).map((_, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className="matchCard">
                                                        <div className="matchCardHeader">
                                                            <div className="left">
                                                                <span className="seriesname"><Skeleton width={150} height={15} /></span>
                                                            </div>
                                                            <div className="right justify-content-end">
                                                                <span className="finished"><Skeleton width={50} height={15} /></span>
                                                            </div>
                                                        </div>
                                                        <div className="matchCardMiddle">

                                                            <div className="matchCardMiddleLeft">
                                                                <div className="matchTeam">
                                                                    <figure className="matchTeamMedia">
                                                                        <Skeleton width={20} height={20} circle />
                                                                    </figure>
                                                                    <Skeleton width={20} height={20} className="bimg" circle />
                                                                    <h6 className="mb-0 matchTeamname"><Skeleton width={50} height={15} /></h6>
                                                                </div>
                                                                <div className="matchTeam">
                                                                    <figure className="matchTeamMedia">
                                                                        <Skeleton width={20} height={20} circle />
                                                                    </figure>
                                                                    <Skeleton width={20} height={20} className="bimg" circle />
                                                                    <h6 className="mb-0 matchTeamname"><Skeleton width={50} height={15} /></h6>
                                                                </div>
                                                            </div>
                                                            <div className="matchCardMiddleRight">
                                                                <div className="matchCardScore">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="matchCardFooter">
                                                            <div className="left">
                                                                <ul>
                                                                    <li><Skeleton width={50} height={10} /></li>
                                                                    <li><Skeleton width={50} height={10} /></li>
                                                                </ul>
                                                            </div>
                                                            <div className="right">
                                                                <span className="name"><Skeleton width={20} height={15} /></span>
                                                                <span className="back"><Skeleton width={20} height={15} /></span>
                                                                <span className="lay"><Skeleton width={20} height={15} /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="section-title">
                                        <h2>Matches for you</h2>
                                    </div>
                                    <ul className="customTabs">
                                        <li className={activeTab === 'All' ? 'active' : ''} onClick={() => handleToggle('All')} >All</li>
                                        <li className={activeTab === 'Live' ? 'active' : ''} onClick={() => handleToggle('Live')} >Live {liveMatchsCount > 0 ? '('+liveMatchsCount+')':null }</li>
                                        <li className={activeTab === 'Upcoming' ? 'active' : ''} onClick={() => handleToggle('Upcoming')}>Upcoming</li>
                                        <li className={activeTab === 'Finished' ? 'active' : ''} onClick={() => handleToggle('Finished')} >Finished</li>
                                    </ul>
                                    {filterIsLoading ?
                                        <div className="matchSlider">
                                            <Swiper spaceBetween={15} slidesPerView={3}>
                                                {Array.from({ length: 5 }).map((_, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="matchCard">
                                                            <div className="matchCardHeader">
                                                                <div className="left">
                                                                    <span className="seriesname"><Skeleton width={150} height={15} /></span>
                                                                </div>
                                                                <div className="right justify-content-end">
                                                                    <span className="finished"><Skeleton width={50} height={15} /></span>
                                                                </div>
                                                            </div>
                                                            <div className="matchCardMiddle">

                                                                <div className="matchCardMiddleLeft">
                                                                    <div className="matchTeam">
                                                                        <figure className="matchTeamMedia">
                                                                            <Skeleton width={20} height={20} circle />
                                                                        </figure>
                                                                        <Skeleton width={20} height={20} className="bimg" circle />
                                                                        <h6 className="mb-0 matchTeamname"><Skeleton width={50} height={15} /></h6>
                                                                    </div>
                                                                    <div className="matchTeam">
                                                                        <figure className="matchTeamMedia">
                                                                            <Skeleton width={20} height={20} circle />
                                                                        </figure>
                                                                        <Skeleton width={20} height={20} className="bimg" circle />
                                                                        <h6 className="mb-0 matchTeamname"><Skeleton width={50} height={15} /></h6>
                                                                    </div>
                                                                </div>
                                                                <div className="matchCardMiddleRight">
                                                                    <div className="matchCardScore">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="matchCardFooter">
                                                                <div className="left">
                                                                    <ul>
                                                                        <li><Skeleton width={50} height={10} /></li>
                                                                        <li><Skeleton width={50} height={10} /></li>
                                                                    </ul>
                                                                </div>
                                                                <div className="right">
                                                                    <span className="name"><Skeleton width={20} height={15} /></span>
                                                                    <span className="back"><Skeleton width={20} height={15} /></span>
                                                                    <span className="lay"><Skeleton width={20} height={15} /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                        :
                                        <div className="matchSlider">
                                            <Swiper spaceBetween={15}
                                                breakpoints={{
                                                    0: {
                                                        slidesPerView: 1.2,
                                                    },
                                                    768: {
                                                        slidesPerView: 1.2,
                                                    },
                                                    992: {
                                                        slidesPerView: 3,
                                                    },
                                                }}>
                                                {filteredMatches.map((matchlist, index) => (
                                                    <SwiperSlide key={index}>
                                                        <MatchCard matchlist={matchlist} />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeMatchSection;

import React, { useEffect, useRef, useState } from "react";
import { ApiService } from '../../Component/Services/apiservices';
import { useParams } from 'react-router-dom';
import Header from "../../Component/Header/index";
import Footer from "../../Component/Footer/index";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
function Pages() {
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [pagesData, setPagesData] = useState({})

  useEffect(() => {
    if (didMountRef.current) {
      getPageData()
    }
    didMountRef.current = false;
  }, []);

  const getPageData = () => {
    const PageData = {
      slug: slug,
    };

    ApiService.postData("page-data", PageData).then((res) => {
      if (res.status === "success") {
        setPagesData(res.pagedata);
      } else {
        console.error('Error fetching page data:', res.message);
      }
    })
      .catch((error) => {
        console.error('Error fetching page data:', error);
      });
  }


  return (
    <>
      <Header />
      <div className="subheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>{pagesData.page_name}</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active href="javascript:void(0)">
                  {pagesData.page_name}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
      {pagesData.page_content ? (
        <section className="section-gap-small match-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div dangerouslySetInnerHTML={{ __html: pagesData.page_content }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      <Footer />
    </>
  );
}

export default Pages;

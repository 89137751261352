import { BrowserView, MobileView } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useEffect, useRef, useState } from 'react';
import { ApiService } from '../Services/apiservices';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
const BottomHeader = () => {
    const [featuredData, setFeaturedData] = useState([])
    const [loading, setLoading] = useState(true);
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getFeaturedSeries()
        }
        didMountRef.current = false
    }, [featuredData])

    const getFeaturedSeries = () => {
        ApiService.fetchData("featuredSeries").then((res) => {
            if (res.status === "success") {
                setFeaturedData(res.seriesdata)
                setLoading(false);
            }
        })
    }
    const handleLink = (seriesId) => {
        navigate(`/series-detail/${seriesId}`);
    };
    return (
        <>
            <BrowserView>
                <div className='header-bottom'>
                    {loading ? (
                        <div className="container">
                            <div className="hb-box"><Skeleton height={20} width={120} /></div>
                            <div className="hb-slider">
                                <div className='hb-slider-content'>
                                    <Swiper spaceBetween={15} slidesPerView='auto'>
                                        {Array(5).fill().map((_, index) => (
                                            <SwiperSlide key={index}><Skeleton height={20} width={200} /></SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    ) : (
                        featuredData && featuredData.length > 0 && (
                            <div className="container">
                                <div className="hb-box">Featured Series</div>
                                <div className="hb-slider">
                                    <div className='hb-slider-content'>
                                        <Swiper spaceBetween={15} slidesPerView='auto'>
                                            {featuredData.map((series, index) => (
                                                <SwiperSlide key={index}><a href="javascript:void(0)" onClick={(e) => handleLink(series.series_slug)} className='hb-series'>{series.series_title}</a></SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </BrowserView>
            <MobileView>
                <div className='header-bottom'>
                    {loading ? (
                        <div className="container px-0">
                            <div className="hb-box"><Skeleton height={15} width={100} /></div>
                            <div className="hb-slider">
                                <div className='hb-slider-content'>
                                    <Swiper spaceBetween={15} slidesPerView='auto'>
                                        {Array(8).fill().map((_, index) => (
                                            <SwiperSlide key={index}><Skeleton height={15} width={150} /></SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    ) : (
                        featuredData && featuredData.length > 0 && (
                            <div className="container px-0">
                                <div className="hb-box">Featured Series</div>
                                <div className="hb-slider">
                                    <div className='hb-slider-content'>
                                        <Swiper spaceBetween={15} slidesPerView='auto'>
                                            {featuredData.map((series, index) => (
                                                <SwiperSlide key={index}><a href="javascript:void(0)" onClick={(e) => handleLink(series.series_slug)} className='hb-series'>{series.series_title}</a></SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </MobileView>
        </>
    )
}

export default BottomHeader